import { useSession } from 'src/hooks';
import { ManageAccountContentPage } from '../ManageAccountContentPage/ManageAccountContentPage';
import { SubscriptionPlansForm } from '../SubscriptionPlansForm';
import { CancelSubscription } from '../CancelSubscription';
import './SubscriptionPlans.scss';

export const SubscriptionPlans = () => {
  const { isSubscribedTier } = useSession();

  return (
    <ManageAccountContentPage title="Subscription">
      <div className="nj-subscription-plans">
        <SubscriptionPlansForm />

        {isSubscribedTier && (
          <>
            <hr className="divider" />

            <CancelSubscription />
          </>
        )}
      </div>
    </ManageAccountContentPage>
  );
};
