import { fetchBaseQuery } from '@reduxjs/toolkit/query/';
import { v4 as uuid_v4 } from 'uuid';
import {
  tasksBaseUrl,
  feedbackBaseUrl,
  contactsBaseUrl,
  tokenBaseUrl,
  jsonBaseUrl,
  combusBaseUrl,
  metahumanBaseUrl,
  matchmakerBaseUrl,
  guestUsersBaseUrl,
  walletBaseUrl,
  contentBaseUrl,
  bffTasksBaseUrl,
} from '../constants/index';
import {
  X_NINJA_EVENT_ID_HEADER,
  X_NINJA_BYPASS_USER_ACCESS_CHECK,
} from 'src/constants/api';
import { get_access_token } from 'src/utils';
import { RootState } from 'src/store';
import { toast } from 'react-toastify';
import { retryableFetchBaseQuery } from 'src/utils/rtkQuery';

export const getBaseHeaders = async (headers: Headers) => {
  const token = await get_access_token();

  if (!token) {
    toast.warning(
      'An authentication error happened. Please try to refresh the page.',
    );
    throw new Error('Auth token must not be empty');
  }

  headers.set('Authorization', `Bearer ${token}`);
  headers.set('Content-Type', 'application/json');
  headers.set(X_NINJA_EVENT_ID_HEADER, uuid_v4());
  if (localStorage.getItem(X_NINJA_BYPASS_USER_ACCESS_CHECK)) {
    headers.set(X_NINJA_BYPASS_USER_ACCESS_CHECK, 'true');
  }

  return headers;
};

export const baseTasksQuery = retryableFetchBaseQuery({
  baseUrl: tasksBaseUrl,
  async prepareHeaders(headers) {
    return getBaseHeaders(headers);
  },
});

export const baseFeedbackQuery = retryableFetchBaseQuery({
  baseUrl: feedbackBaseUrl,
  async prepareHeaders(headers) {
    return getBaseHeaders(headers);
  },
});

export const baseNondebugFeedbackQuery = retryableFetchBaseQuery({
  baseUrl: `${tasksBaseUrl}/feedback`,
  credentials: 'same-origin',
  async prepareHeaders(headers) {
    return getBaseHeaders(headers);
  },
});

export const baseUsersV2Query = retryableFetchBaseQuery({
  baseUrl: `${tasksBaseUrl}/users`,
  credentials: 'same-origin',
  async prepareHeaders(headers) {
    return getBaseHeaders(headers);
  },
});

export const baseUsersBFFQuery = retryableFetchBaseQuery({
  baseUrl: `${bffTasksBaseUrl}/users`,
  credentials: 'same-origin',
  async prepareHeaders(headers) {
    return getBaseHeaders(headers);
  },
});

export const baseContactsQuery = retryableFetchBaseQuery({
  baseUrl: contactsBaseUrl,
  async prepareHeaders(headers) {
    return getBaseHeaders(headers);
  },
});

export const baseTokenQuery = retryableFetchBaseQuery({
  baseUrl: `${tokenBaseUrl}/tokens`,
  credentials: 'same-origin',
  async prepareHeaders(headers) {
    return getBaseHeaders(headers);
  },
});

export const baseAuthQuery = retryableFetchBaseQuery({
  baseUrl: `${tokenBaseUrl}/auth/`,
  credentials: 'same-origin',
  async prepareHeaders(headers) {
    return getBaseHeaders(headers);
  },
});

export const baseMsftQuery = retryableFetchBaseQuery({
  baseUrl: `${tokenBaseUrl}/msft`,
  credentials: 'same-origin',
  async prepareHeaders(headers) {
    return getBaseHeaders(headers);
  },
});

export const baseJSONQuery = fetchBaseQuery({
  baseUrl: jsonBaseUrl,
  headers: {
    'Cache-Control': 'no-cache',
  },
});

export const baseCombusQuery = retryableFetchBaseQuery({
  baseUrl: combusBaseUrl,
  async prepareHeaders(headers) {
    return getBaseHeaders(headers);
  },
});

export const baseMetahumanQuery = retryableFetchBaseQuery({
  baseUrl: metahumanBaseUrl,
  async prepareHeaders(headers) {
    headers.set('Accept', 'application/json');
    return getBaseHeaders(headers);
  },
});

export const baseMatchmakerQuery = retryableFetchBaseQuery({
  baseUrl: matchmakerBaseUrl,
  async prepareHeaders(headers) {
    headers.set('Accept', 'application/json');
    return getBaseHeaders(headers);
  },
});

// endpoints under this path don't require authentication
export const baseGuestUsersQuery = retryableFetchBaseQuery({
  baseUrl: guestUsersBaseUrl,
  async prepareHeaders(headers) {
    headers.set('Content-Type', 'application/json');
    headers.set(X_NINJA_EVENT_ID_HEADER, uuid_v4());
    return headers;
  },
});

export const baseWalletQuery = retryableFetchBaseQuery({
  baseUrl: walletBaseUrl,
  async prepareHeaders(headers) {
    return getBaseHeaders(headers);
  },
});

export const baseContentQuery = retryableFetchBaseQuery({
  baseUrl: contentBaseUrl,
  async prepareHeaders(headers, { getState }) {
    const token = await get_access_token();
    headers.set('Authorization', `Bearer ${token}`);
    headers.set('Content-Type', 'application/json');
    headers.set(
      'X-Ninja-User-Id',
      (getState() as RootState).session?.appUser.user_id,
    );
    return headers;
  },
});
