import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { Modal } from 'src/components/Modal';
// import { PlanSection } from './components/PlanSection';
// import { PlanSectionType } from 'src/types';
// import { useBreakpoint } from 'src/hooks';
import './PlanCardModal.scss';

/**
 * @deprecated
 */

export const PlanCardModal = () => {
  const { onTogglePlanCardModal } = useContext(SessionContext);
  // const { isMobile } = useBreakpoint();

  // const PlanSections: [PlanSectionType, PlanSectionType] = isMobile
  //   ? ['pro-section', 'free-section']
  //   : ['free-section', 'pro-section'];

  const handleClose = () => {
    onTogglePlanCardModal();
  };

  return (
    <Modal
      className="nj-upgrade-modal"
      overlayClassName="nj-upgrade-modal-overlay"
      open={false}
      onClose={handleClose}
      blockScroll={false}
    >
      <div className="nj-upgrade-modal--container">
        {/* TODO(olha): monthly/early switch will be add later */}

        <div className="nj-upgrade-modal--content-wrapper">
          <div className="nj-upgrade-modal--content">
            {/*{PlanSections.map((item) => (*/}
            {/*  <PlanSection*/}
            {/*    key={item}*/}
            {/*    type={item}*/}
            {/*    onOpenMobileCheckout={() => {*/}
            {/*      //todo deprecated*/}
            {/*    }}*/}
            {/*  />*/}
            {/*))}*/}
          </div>
        </div>
      </div>
    </Modal>
  );
};
