import { useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { ManageTasksChatPage } from 'src/pages/ManageTasksChatPage';
import { AccessModal } from 'src/pages/AuthPages/components/AccessModal';
import { useGoogleResourcesAccess, useSession } from 'src/hooks';
import { UserStatus } from 'src/types/models/UserStatus';
import {
  REDIRECT_TO_STRIPE_EXP_TIME,
  REDIRECT_TO_STRIPE_TIMESTAMP,
  VERSION_NUMBER,
} from 'src/constants';
import { MaintenanceScreen } from 'src/pages/AuthPages/components/MaintenanceScreen';
import { QuickTourModal } from 'src/pages/AuthPages/components/QuickTourModal';
import { QueueScreen } from 'src/pages/AuthPages/components/QueueScreen';
import { UpdatesModal } from 'src/pages/AuthPages/components/UpdatesModal';
import {
  AppRoutes,
  BillingSettingsNestedRoutes,
  UserSettingsNestedRoutes,
} from 'src/types';
import { ManageAccountPage } from 'src/pages/ManageAccountPage';
import { SubscriptionPlans } from 'src/pages/ManageAccountPage/components/SubscriptionPlans';
import { SubscriptionsBilling } from 'src/pages/ManageAccountPage/components/SubscriptionsBilling';
import { UpdatePlanModal } from 'src/components/UpdatePlanModal';
import { useCanConnectToGoogle } from 'src/hooks/useCanConnectToGoogle';
import { env } from 'src/env';
import { Profile } from 'src/pages/ManageAccountPage/components/Profile';
import { Integrations } from 'src/pages/ManageAccountPage/components/Integrations';
import { General } from 'src/pages/ManageAccountPage/components/General';
import { ContactNinjaModal } from 'src/components/ContactNinjaModal';
import { useAppDispatch } from 'src/store';
import { PaymentPage } from 'src/pages/PaymentPage';
import { PlanCardModal } from 'src/components/PlanCardModal';
import { PaymentBetaPage } from 'src/pages/PaymentBetaPage';

export const PrivateContent = () => {
  const [showQuickTourModal, setIsShowQuickTourModal] = useState(false);
  const [isShowUpdatesModal, setIsShowUpdatesModal] = useState(false);
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const {
    appUser,
    isFirstSchedulerTask,
    isAccessModalShown,
    updateIsAccessModalShown,
    isEnterpriseTier,
    isSubscribedTier,
  } = useSession();

  const canConnectToGoogle = useCanConnectToGoogle();

  const {
    canAccessDirectory,
    canAccessContacts,
    canAccessCalendar,
    isLoadingConnectionCheck,
    isFetchingConnectionCheck,
  } = useGoogleResourcesAccess();

  const isShowAccess =
    canConnectToGoogle &&
    isFirstSchedulerTask &&
    !isAccessModalShown &&
    !isLoadingConnectionCheck &&
    !isFetchingConnectionCheck &&
    !!appUser?.user_id &&
    (!canAccessDirectory || !canAccessContacts || !canAccessCalendar);

  useEffect(() => {
    // only show the updates modal in beta and production
    if (
      env.REACT_APP_ENVIRONMENT === 'gamma' ||
      env.REACT_APP_ENVIRONMENT === 'production'
    ) {
      if (
        !!appUser?.metadata?.app_version &&
        appUser?.metadata?.app_version !== VERSION_NUMBER
      ) {
        setIsShowUpdatesModal(true);
      }
    }
  }, [appUser?.metadata?.app_version, appUser?.user_id]);

  // TODO(olha): move to separate hook
  useEffect(() => {
    if (isSubscribedTier || isEnterpriseTier) {
      localStorage.removeItem(REDIRECT_TO_STRIPE_TIMESTAMP);
      return;
    }

    const redirectTimestamp = localStorage.getItem(
      REDIRECT_TO_STRIPE_TIMESTAMP,
    );

    const shouldRedirectToStripe =
      dayjs().valueOf() - Number(redirectTimestamp) <=
      REDIRECT_TO_STRIPE_EXP_TIME;

    if (shouldRedirectToStripe) {
      navigate(AppRoutes.PAYMENT);
      localStorage.removeItem(REDIRECT_TO_STRIPE_TIMESTAMP);
      return;
    } else {
      localStorage.removeItem(REDIRECT_TO_STRIPE_TIMESTAMP);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubscribedTier, isEnterpriseTier, appUser.tier_id, dispatch]);

  useEffect(() => {
    if (
      appUser?.status !== UserStatus.MAINTENANCE &&
      appUser?.metadata?.user_master_status !== UserStatus.MAINTENANCE
    ) {
      setMaintenanceMode(false);
    } else {
      setMaintenanceMode(true);
    }
  }, [appUser?.metadata?.user_master_status, appUser?.status]);

  const handleCloseAccessModal = () => {
    updateIsAccessModalShown(true);
  };

  // TODO: stan - temporary for QA
  const mainPaymentPagePath = useMemo(() => {
    if (env.REACT_APP_ENVIRONMENT !== 'production') {
      return AppRoutes.PAYMENT_BETA;
    } else {
      return AppRoutes.PAYMENT;
    }
  }, []);

  const betaPaymentPagePath = useMemo(() => {
    if (env.REACT_APP_ENVIRONMENT !== 'production') {
      return AppRoutes.PAYMENT;
    } else {
      return AppRoutes.PAYMENT_BETA;
    }
  }, []);

  if (maintenanceMode) {
    return <MaintenanceScreen />;
  }

  if (appUser?.status === UserStatus.QUEUE) {
    return <QueueScreen />;
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<ManageTasksChatPage />}></Route>

        <Route path={mainPaymentPagePath} element={<PaymentPage />} />
        {env.REACT_APP_ENVIRONMENT !== 'production' && (
          <Route path={betaPaymentPagePath} element={<PaymentBetaPage />} />
        )}

        <Route path={AppRoutes.MANAGE_ACCOUNT} element={<ManageAccountPage />}>
          <Route index element={<SubscriptionPlans />} />
          <Route
            path={UserSettingsNestedRoutes.PROFILE}
            element={<Profile />}
          />
          <Route
            path={UserSettingsNestedRoutes.INTEGRATIONS}
            element={<Integrations />}
          />
          <Route
            path={UserSettingsNestedRoutes.GENERAL}
            element={<General />}
          />
          {/* <Route
            path={UserSettingsNestedRoutes.ACTIVITY}
            element={<SubscriptionActivity />}
          /> */}
          <Route
            path={BillingSettingsNestedRoutes.SUBSCRIPTION}
            element={<SubscriptionPlans />}
          />
          <Route
            path={BillingSettingsNestedRoutes.BILLING}
            element={<SubscriptionsBilling />}
          />
        </Route>

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <AccessModal isOpen={isShowAccess} onClose={handleCloseAccessModal} />
      <UpdatesModal isOpen={isShowUpdatesModal} />
      <UpdatePlanModal />

      <QuickTourModal
        isOpen={showQuickTourModal}
        onClose={() => setIsShowQuickTourModal(false)}
      />
      <ContactNinjaModal />

      {/*todo remove in the future but fix styles*/}
      <PlanCardModal />
    </>
  );
};
