import {
  PaymentPlanInterval,
  PlanSectionType,
  SubscriptionPlan,
} from 'src/types';
import { UpgradeForm } from '../UpgradeForm';
import './PlanSection.scss';
import { BASIC_FEATURES, SVG_SIZE_M } from 'src/constants';
import { Check } from '@phosphor-icons/react';
import { useSession } from 'src/hooks';
import { useMemo } from 'react';

interface PlanSectionProps {
  type: PlanSectionType;
  onOpenCheckout: (type: PlanSectionType, period: PaymentPlanInterval) => void;
  period: PaymentPlanInterval;
  selected?: PlanSectionType;
  plans?: SubscriptionPlan[];
}

export const PlanSection = ({
  type,
  onOpenCheckout,
  period,
  selected,
  plans,
}: PlanSectionProps) => {
  const { isFreeTier } = useSession();
  const isBasicSection = type === 'Basic';

  const displayedPlan = useMemo(
    () =>
      plans?.find(
        (plan) =>
          plan.period === period && plan.plan_tier === type.toLowerCase(),
      ),
    [plans, type, period],
  );

  return (
    <div className="nj-plan-section">
      <h5 className="nj-plan-section--title-wrapper">
        <span className="nj-plan-section--title">{type}</span>
      </h5>

      {!isBasicSection ? (
        <UpgradeForm
          onOpenCheckout={onOpenCheckout}
          period={period}
          type={type}
          isSelected={selected === type}
          selectedPlan={displayedPlan}
        />
      ) : (
        <div className="nj-plan-section--current-plan">
          <p className="nj-plan-section--current-plan-price">
            <span className="nj-plan-section--current-plan-title">
              $0&nbsp;
            </span>
            <span className="nj-plan-section--current-plan-subtitle">/mo</span>
          </p>
          <p className="nj-plan-section--current-plan-sales-description">
            &nbsp;
          </p>
          <p className="nj-plan-section--current-plan-description">
            10 tasks per day
          </p>
          <button
            type="button"
            className="nj-plan-section--current-plan-button"
            disabled
          >
            {isFreeTier ? 'Current plan' : 'Get Basic'}
          </button>
          <div className="nj-plan-section--current-plan-features-list">
            <h5 className="nj-plan-section--current-plan-features-list-title">
              Includes
            </h5>
            {BASIC_FEATURES.map((item) => (
              <div
                className="nj-plan-section--current-plan-feature-row"
                key={item.title}
              >
                <Check size={SVG_SIZE_M} />
                <span>{item.title}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
