import { useContext } from 'react';
import { ArrowsCounterClockwise } from '@phosphor-icons/react';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { Message } from 'src/types';
import { SVG_SIZE_M } from 'src/constants';

interface RegenerateMessageButtonProps {
  message: Message;
  disabled?: boolean;
  hideAllOptions: () => void;
}

export const RegenerateMessageButton = ({
  message,
  disabled,
  hideAllOptions,
}: RegenerateMessageButtonProps) => {
  const { setThreadInputBoxValue } = useContext(ThreadInputBoxContext);

  const userInput = message?.original_query || '';

  const handleButtonClick = () => {
    setThreadInputBoxValue(userInput);
    hideAllOptions();
  };

  return (
    <button
      disabled={!userInput || disabled}
      onClick={handleButtonClick}
      title="Regenerate your question"
    >
      <ArrowsCounterClockwise size={SVG_SIZE_M} />
      Rephrase
    </button>
  );
};
