import { NinjaSubscriptionPlan, TaskSkill } from './api';

export type PlanSectionType = 'Basic' | 'Pro' | 'Ultra';

export interface PaymentPlanOption {
  lookup_key: string;
  amount: number;
  isDefault?: boolean;
  tasksCount?: number;
  isAnnual?: boolean;
  promotion_code?: string;
}

export interface SubscriptionOption {
  plan: PlanSectionType;
  monthly_key: string;
  yearly_key: string;
  originalPrice: number;
  monthlyPrice: number;
  yearlyPrice: number;
  promotion_code?: string;
  firstMonthSale: number;
}

export interface PaymentPlanOptionWithSale extends PaymentPlanOption {
  newAmount: number;
  sale: number;
  couponAmount: number;
}

export interface PaymentsActivity {
  skill: TaskSkill;
  task_hash: string;
  timestamp: string;
  cost: number;
  status: string;
  models: PaymentsActivityModel[];
  components: PaymentsActivityComponent[];
}

export interface PaymentsActivityModel {
  platform: string;
  provider: string;
  model_name: string;
}

export interface PaymentsActivityComponent {
  component: string;
}

export interface PaymentsActivityColumn {
  header: string;
  accessor: keyof PaymentsActivity;
  render?: (activity: PaymentsActivity) => React.ReactNode;
  format?: (value: number | string) => string;
}

export enum PaymentPlanInterval {
  MONTHLY = 'monthly',
  ANNUALLY = 'annually',
}

export interface PaymentsSubscriptionInfo {
  inception_timestamp: string;
  is_cancelled: boolean;
  curr_period_start_timestamp: string;
  curr_period_end_timestamp: string;
  interval: PaymentPlanInterval;
  price_info: {
    amount: number;
    currency: string;
    lookup_key: string;
    credit: number;
  };
}

export interface PlanOption {
  title: string;
  withBorder?: boolean;
  link?: { href: string; label: string };
  isExternalModels?: boolean;
}

export type WelcomeUpsellModalType =
  | 'open-tier-upsell-modal'
  | 'pro-trial-welcome-modal'
  | 'pro-trial-upsell-modal'
  | 'free-tier-upsell-modal'
  | 'enterprise-tier-welcome-modal'
  | 'pro-welcome-modal'
  | 'ultra-welcome-modal';

export interface WelcomeUpsellData {
  title: string;
  description: string;
  continueButtonTitle: string;
  isContinueButtonBlue?: boolean;
  upgradeButtonId: string;
  isUpgradeButtonBlue?: boolean;
  reverseButtonsOrder?: boolean;
  imageURL: string;
  duration: number;
}

export type WelcomeUpsellContent = Record<
  WelcomeUpsellModalType,
  WelcomeUpsellData
>;

export interface CreateSubscriptionRequest {
  user_id: string;
  plan_id: string;
}

export interface GetSubscriptionPlansRequest {
  user_id: string;
}

export interface SubscriptionIntent {
  subscription_id: string;
  client_secret: string;
}

export interface SubscriptionPlanMetadata {
  paypal: {
    plan_id: string;
  };
  [key: string]: string | unknown;
}

// TODO(olha): generate types when BE will be ready
export interface SubscriptionPlan {
  currency: string;
  discounted_price: number;
  one_time_discount_amount: number;
  one_time_discount_percentage: number;
  original_price: number;
  period: PaymentPlanInterval;
  plan_id: string; // should be enum
  plan_tier: NinjaSubscriptionPlan;
  recurring_discount_amount: number;
  recurring_discount_percentage: number;
  status: string; // should be enum
  total_discount_amount: number;
  total_due_amount: number;
  total_original_amount: number;
  metadata?: SubscriptionPlanMetadata;
}
