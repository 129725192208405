import { ChangeEvent, useContext, useRef } from 'react';
import { Paperclip } from '@phosphor-icons/react';
import { AttachmentFileInputId, SVG_SIZE_L } from 'src/constants';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
  useAttachFile,
  useBreakpoint,
  useOutsideClick,
  useSession,
  useVisible,
} from 'src/hooks';
import ThreadInputBoxContext, {
  ThreadInputBoxContextType,
} from 'src/contexts/ThreadInputBoxContext';
import { useGetUserTaskQuotaInfoQuery } from 'src/store/services';
import { GuestAuthLinks } from 'src/pages/AuthPages/components/GuestAuthLinks';
import { UpgradeLearnMoreButtons } from 'src/pages/AuthPages/components/UpgradeLearnMoreButtons';

export const AddAttachmentButton = () => {
  const { addFile } = useAttachFile();
  const { isMobile } = useBreakpoint();
  const containerRef = useRef<HTMLDivElement>(null);
  const { threadInputBoxFile, promptLoading } =
    useContext<ThreadInputBoxContextType>(ThreadInputBoxContext);
  const {
    isVisible: isVisibleTooltip,
    handleVisibilitySet: onShowTooltip,
    handleVisibilityRemove: onHideTooltip,
  } = useVisible();
  useOutsideClick(containerRef, onHideTooltip);

  const { isOpenTier, isFreeTier, appUser, isProTrialTier, isSubscribedTier } =
    useSession();

  const {
    data: quotaData,
    isLoading,
    isError,
    isFetching,
  } = useGetUserTaskQuotaInfoQuery(
    {
      user_id: appUser.user_id,
    },
    {
      skip: !appUser.user_id || isSubscribedTier || isProTrialTier,
    },
  );

  const handleAttachClick = () => {
    if (isOpenTier || isFreeTier || (!!quotaData && quotaData?.count === 0)) {
      onShowTooltip();
    } else {
      document.getElementById(AttachmentFileInputId)?.click();
    }
  };

  const handleFileSelect = async (event: ChangeEvent) => {
    const files = (event.target as HTMLInputElement)?.files;
    const file = files ? files[0] : null;
    if (file) {
      await addFile(file);
    }
  };

  const isDisabled = isLoading || isFetching || isError || promptLoading;

  return (
    <div ref={containerRef}>
      <button
        className="nj-thread-input-box--attach-button"
        disabled={isDisabled}
        onClick={handleAttachClick}
        data-tooltip-id="attachment-tooltip"
        data-tooltip-place={isMobile ? 'bottom-start' : 'top-start'}
      >
        <Paperclip size={SVG_SIZE_L} />
      </button>
      <input
        id="attachFile"
        type="file"
        accept=".txt,.docx,.pdf,.csv,.tsv,.png,.jpg,.jpeg"
        onChange={handleFileSelect}
        className="nj-thread-input-box--attach-input"
      />

      <ReactTooltip
        clickable
        id="attachment-tooltip"
        disableStyleInjection
        noArrow={true}
        className="nj-attachment-tooltip"
        isOpen={isVisibleTooltip}
        offset={isMobile ? 0 : 20}
        imperativeModeOnly={true}
        openOnClick
      >
        {!!threadInputBoxFile ? (
          <span>Only one attachment is allowed per request</span>
        ) : (
          <>
            <span>
              {isOpenTier
                ? 'You’ll need a sign-in account to add attachment'
                : isFreeTier
                  ? 'Upgrade to add an attachment.'
                  : 'Sorry you have reached your daily limit'}
            </span>

            {isOpenTier && (
              <GuestAuthLinks dataGTM="Chat-thread-attach-file-signup" />
            )}

            {(isProTrialTier || isFreeTier) && (
              <UpgradeLearnMoreButtons dataGTM="Chat-thread-attach-file-upgrade" />
            )}
          </>
        )}
      </ReactTooltip>
    </div>
  );
};
