import { useContext } from 'react';
import { PaperPlaneRight } from '@phosphor-icons/react';
import ThreadInputBoxContext, {
  ThreadInputBoxContextType,
} from 'src/contexts/ThreadInputBoxContext';
import { useSubmitUserInput } from 'src/hooks';
import { BasicButton } from 'src/components/Button';
import { SVG_SIZE_M } from 'src/constants';

interface SendMessageButtonProps {
  isDisabled: boolean;
}

export const SendMessageButton = ({ isDisabled }: SendMessageButtonProps) => {
  const { threadInputBoxValue } = useContext<ThreadInputBoxContextType>(
    ThreadInputBoxContext,
  );

  const { onSubmitUserInput } = useSubmitUserInput();

  const handleSubmit = () => {
    onSubmitUserInput(threadInputBoxValue);
  };

  return (
    <BasicButton
      data-e2e="main-submit-button"
      aria-label="Send a message"
      disabled={isDisabled}
      className="nj-chat-form--submit-button"
      onClick={handleSubmit}
    >
      <PaperPlaneRight size={SVG_SIZE_M} color="currentColor" weight="fill" />
    </BasicButton>
  );
};
