import { useCallback, useContext } from 'react';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { FILE_REGEX } from 'src/constants';
import log from 'src/utils/logger';
import { useSession } from 'src/hooks';
import { useDeleteFileMutation } from 'src/store/services';

export const useDeleteAttachment = () => {
  const { appUser } = useSession();

  const [deleteFileFromS3] = useDeleteFileMutation();

  const { deleteAttachment, fileDataToSend } = useContext(
    ThreadInputBoxContext,
  );

  const handleDeleteAttachment = useCallback(async () => {
    const isFileRemovable = FILE_REGEX.test(fileDataToSend);
    deleteAttachment();
    try {
      if (isFileRemovable) {
        const file_name =
          fileDataToSend.match(FILE_REGEX) !== null
            ? (fileDataToSend.match(FILE_REGEX) || [])[2].replaceAll('"', '')
            : '';
        if (file_name.length > 0) {
          await deleteFileFromS3({
            user_id: appUser.user_id,
            file_name,
          });
        }
      }
    } catch (error) {
      log.error(error);
    }
  }, [fileDataToSend, deleteAttachment, appUser, deleteFileFromS3]);

  return {
    onDeleteAttachment: handleDeleteAttachment,
  };
};
