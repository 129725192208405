import styles from './SpinnerSimple.module.scss';

interface SpinnerSimpleProps {
  size?: number;
  className?: string;
}

export const SpinnerSimple = ({ size = 40, className }: SpinnerSimpleProps) => {
  const style = {
    width: size,
    height: size,
  };

  return (
    <div style={style} className={className}>
      <span style={style} className={styles.spinner} />
    </div>
  );
};
