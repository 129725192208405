import { SummaryRow } from '../SummaryRow';
import styles from './SummarySection.module.scss';
import { getRoundedPriceWithCurrency } from 'src/utils';
import { SubscriptionPlan } from 'src/types';

interface SummarySectionProps {
  currentPlan?: SubscriptionPlan;
}

export const SummarySection = ({ currentPlan }: SummarySectionProps) => {
  if (!currentPlan) {
    return null;
  }

  const isMonthlyPeriod = currentPlan.period === 'monthly';

  return (
    <div className={styles.root}>
      <div className={styles.checkoutResultSection}>
        <div className={styles.checkoutResultSectionRow}>
          <span>Ninja Pro subscription</span>
          <span>
            {getRoundedPriceWithCurrency(currentPlan.total_original_amount)}/
            {isMonthlyPeriod ? 'mo' : 'yr'}
          </span>
        </div>
      </div>

      <hr className="divider no-margin" />

      <div className={styles.checkoutRowWrapper}>
        <SummaryRow
          label="Subtotal"
          value={getRoundedPriceWithCurrency(currentPlan.total_original_amount)}
        />
        <SummaryRow
          label="Discount"
          value={`-${getRoundedPriceWithCurrency(currentPlan.total_discount_amount)}`}
        />
        <SummaryRow label="Tax & fees" value={getRoundedPriceWithCurrency(0)} />

        <div className={styles.checkoutResultSectionRow}>
          <span>Due today (USD)</span>

          <span>
            {getRoundedPriceWithCurrency(currentPlan.total_due_amount)}
          </span>
        </div>
      </div>
    </div>
  );
};
