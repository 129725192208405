import styles from './PeriodSwitch.module.scss';
import { PaymentPlanInterval } from 'src/types';
import cn from 'classnames';
import { SALE_PER_FIRST_YEAR } from 'src/constants';

type PeriodSwitchProps = {
  selectedPeriod: PaymentPlanInterval;
  onChangePeriod: (value: PaymentPlanInterval) => void;
};

export type PaymentPeriod = 'Monthly' | 'Yearly';

const periods: Record<PaymentPlanInterval, PaymentPeriod> = {
  [PaymentPlanInterval.MONTHLY]: 'Monthly',
  [PaymentPlanInterval.ANNUALLY]: 'Yearly',
};

export const PeriodSwitch = ({
  selectedPeriod,
  onChangePeriod,
}: PeriodSwitchProps) => {
  return (
    <div className={styles.root}>
      {Object.values(PaymentPlanInterval).map((item: PaymentPlanInterval) => {
        return (
          <button
            className={cn(styles.periodButton, {
              [styles.active]: item === selectedPeriod,
            })}
            onClick={() => onChangePeriod(item)}
            key={item}
          >
            {periods[item]}
          </button>
        );
      })}
      <div className={styles.saleLabel}>{SALE_PER_FIRST_YEAR}% OFF</div>
    </div>
  );
};
