import { lazy, Suspense, useMemo, useRef } from 'react';
import cn from 'classnames';
import { Sliders } from '@phosphor-icons/react';
import {
  useVisible,
  useOutsideClick,
  useSession,
  useUserData,
} from 'src/hooks';
import { NinjaAgentsButton } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideNavigationButtons/NinjaAgentsButton';
import { ExternalModelsButton } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideNavigationButtons/ExternalModelsButton';
import styles from './ModelsMenu.module.scss';
import {
  AvailableImageGenerationModels,
  ImageGenerationModels,
  ONBOARDING_HINT_STEP,
  SVG_SIZE_M,
} from 'src/constants';

const LazyReactTooltip = lazy(() =>
  import('react-tooltip').then((module) => ({ default: module.Tooltip })),
);

export const ModelsMenu = () => {
  const onboardingHintStep = localStorage.getItem(ONBOARDING_HINT_STEP);
  const containerRef = useRef<HTMLDivElement>(null);
  const containerOnboardingHintRef = useRef<HTMLDivElement>(null);
  const { onboardingHintsStep, changeOnboardingHintStep, appUser } =
    useSession();
  const { updateUserData } = useUserData();

  const { isVisible, handleVisibilityRemove, handleVisibilityToggle } =
    useVisible();

  const isShowHint =
    onboardingHintsStep === 1 && !onboardingHintStep && !appUser.onboarded;

  const handleOutsideOnboardingTooltipClick = async () => {
    if (onboardingHintsStep === 1) {
      changeOnboardingHintStep(0);
      await updateUserData(
        {
          onboarded: true,
        },
        false,
      );
      localStorage.setItem(ONBOARDING_HINT_STEP, '0');
    }
  };

  useOutsideClick(containerRef, handleVisibilityRemove);
  useOutsideClick(containerOnboardingHintRef, () =>
    handleOutsideOnboardingTooltipClick(),
  );

  const countOfNinjaAgentsSelectedItems = useMemo(() => {
    return (
      Number(appUser?.settings?.research?.deep_research?.enabled) +
      Number(appUser?.settings?.research?.web_search?.search_images) +
      Number(appUser?.settings?.research?.web_search?.search_videos)
    );
  }, [
    appUser?.settings?.research?.deep_research?.enabled,
    appUser?.settings?.research?.web_search?.search_images,
    appUser?.settings?.research?.web_search?.search_videos,
  ]);

  const countOfSelectedExternalModels = useMemo(() => {
    const modelsList = [
      ...(appUser.settings?.external_models?.amazon_bedrock || []),
      ...(appUser.settings?.external_models?.google || []),
      ...(appUser.settings?.external_models?.openai || []),
      ...(appUser?.settings?.image_gen_settings?.models || []).filter(
        (item) =>
          !AvailableImageGenerationModels.includes(
            item.model as ImageGenerationModels,
          ),
      ),
    ];
    return modelsList.filter((item) => !!item.enabled).length;
  }, [
    appUser.settings?.external_models,
    appUser?.settings?.image_gen_settings,
  ]);

  const totalCount =
    Number(countOfNinjaAgentsSelectedItems) +
    Number(countOfSelectedExternalModels);

  return (
    <div className={styles.root} ref={containerRef}>
      <button
        onClick={handleVisibilityToggle}
        id="models-menu-trigger"
        className={cn(styles.triggerButton, { [styles.active]: isVisible })}
        data-tooltip-id="externsal-models-onboarding-tooltip"
        data-tooltip-place="top-end"
      >
        {totalCount > 0 && <span className={styles.counter}>{totalCount}</span>}
        <Sliders size={SVG_SIZE_M} />
      </button>

      <div ref={containerOnboardingHintRef}>
        <Suspense>
          <LazyReactTooltip
            id="externsal-models-onboarding-tooltip"
            noArrow={true}
            className="nj-onboarding-bubble-tooltip external-models"
            isOpen={isShowHint}
            imperativeModeOnly={true}
            openOnClick
          >
            <span>
              Welcome! Set your preferences on Ninja Agent and external AI
              models you like to see.
            </span>
          </LazyReactTooltip>
        </Suspense>
      </div>

      <Suspense>
        <LazyReactTooltip
          anchorSelect="#models-menu-trigger"
          clickable
          noArrow
          openOnClick
          disableStyleInjection
          className={styles.settingsTooltip}
          isOpen={isVisible}
          opacity={1}
          place="bottom-end"
          offset={10}
        >
          <div className={styles.menu} onClick={handleVisibilityRemove}>
            <NinjaAgentsButton />
            <ExternalModelsButton />
          </div>
        </LazyReactTooltip>
      </Suspense>
    </div>
  );
};
