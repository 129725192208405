export const ONBOARDING_HINT_STEP = 'onboarding_hint_step';

export const SHOW_DEEP_RESEARCH_HINT = 'show_deep_research_hint';

export const SHOWN_ONBOARDING_MODAL = 'shown_onboarding_modal';

export const LOCAL_STORAGE_VALUE = '1';

export const IS_USER_PASSED_PASSWORD_PAGE = 'passed_pass_page';

export const GUEST_ACCESS_TOKEN = 'guest_access_token';

export const REDIRECT_TO_STRIPE_TIMESTAMP = 'redirect_to_stripe_timestamp';

export const TRANSACTION_ID = 'transaction_id';
