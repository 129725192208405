import { useMemo, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useBreakpoint } from 'src/hooks';
import styles from './CheckoutSection.module.scss';
import { X } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { env } from 'src/env';

type CheckoutSectionProps = {
  isOpen: boolean;
  onClose: () => void;
  clientSecret: null | string;
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(env.REACT_APP_STRIPE_PK);

export const CheckoutSection = ({
  isOpen,
  onClose,
  clientSecret,
}: CheckoutSectionProps) => {
  const { isMobile, isTablet } = useBreakpoint();
  const checkoutContentRef = useRef<HTMLDivElement>(null);

  const animationClassNames = useMemo(() => {
    if (isMobile) {
      return 'nj-animate-fade';
    }
    if (isTablet) {
      return 'nj-animate-horizontal-slow-appearance';
    }
    return 'nj-animate-right-panel-width-reduce';
  }, [isMobile, isTablet]);

  return (
    <CSSTransition
      in={isOpen}
      timeout={450}
      classNames={animationClassNames}
      nodeRef={checkoutContentRef}
      unmountOnExit
    >
      <div className={styles.embeddedPaymentWrapper} ref={checkoutContentRef}>
        {!clientSecret && (
          <div className={styles.mobileBackButtonRow}>
            <button
              type="button"
              onClick={onClose}
              className={styles.mobileBackButton}
            >
              <span>Checkout</span>
              <X size={SVG_SIZE_M} />
            </button>
          </div>
        )}
        <div className={styles.loadingContainer}>
          <div>
            <div className={styles.loading}>
              {/* TODO(olha): create a loading component */}
              <span className="nj-message-author-picture--spinner" />
            </div>
            <span className={styles.loadingTitle}>
              Loading payment options…
            </span>
          </div>
        </div>

        {!!clientSecret && (
          <div className={styles.embeddedStripeWrapper}>
            <div className={styles.mobileBackButtonRow}>
              <button
                type="button"
                onClick={onClose}
                className={styles.mobileBackButton}
              >
                <span>Checkout</span>
                <X size={SVG_SIZE_M} />
              </button>
            </div>

            <EmbeddedCheckoutProvider
              key={clientSecret}
              stripe={stripePromise}
              options={{
                clientSecret,
              }}
            >
              <EmbeddedCheckout className={styles.embeddedStripe} />
            </EmbeddedCheckoutProvider>
          </div>
        )}
      </div>
    </CSSTransition>
  );
};
