import { ContentPane } from 'src/components/ContentPane';
import { useEffect, useState } from 'react';
import { useBreakpoint, useSession, useVisible } from 'src/hooks';
import {
  REDIRECT_TO_STRIPE_TIMESTAMP,
  SUBSCRIPTIONS,
  SVG_SIZE_L,
  SVG_SIZE_M,
  SVG_SIZE_XXL,
} from 'src/constants';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';
import {
  AppRoutes,
  GTMEvent,
  PaymentPlanInterval,
  PlanSectionType,
  SubscriptionOption,
} from 'src/types';
import styles from './PaymentPage.module.scss';
import log from 'src/utils/logger';
import { useUpgradeSubscriptionMutation } from 'src/store/services';
import { Spinner } from 'src/components/Loading';
import { ArrowLeft, ArrowSquareOut } from '@phosphor-icons/react';
import { PlanSection } from 'src/components/PlanCardModal/components/PlanSection';
import { PeriodSwitch } from 'src/components/PeriodSwitch';
import { LEARN_MORE_PRICES_LINK } from 'src/constants/externalLinks';
import { CheckoutSection } from 'src/pages/PaymentPage/components/CheckoutSection';
import { NinjaAgentsOutlinedIcon } from 'src/images/icons/ninjaAgentsOutlined';
import { sendGTMEvent } from 'src/utils';

export const AddOnAccessExternalLLM = 'AddOnAccessExternalLLM';

export const PaymentPage = () => {
  const [isLoading] = useState<boolean>(false);
  const [selectedPeriod, setSelectedPeriod] = useState<PaymentPlanInterval>(
    PaymentPlanInterval.MONTHLY,
  );
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionOption | null>(
    null,
  );

  const { isMobile } = useBreakpoint();

  const PlanSections: [PlanSectionType, PlanSectionType, PlanSectionType] = [
    'Basic',
    'Pro',
    'Ultra',
  ];

  const {
    isVisible: isEmbeddedCheckoutVisible,
    handleVisibilitySet: onOpenEmbeddedCheckoutVisible,
    handleVisibilityRemove: onCloseEmbeddedCheckoutVisible,
  } = useVisible();

  const {
    isOpenTier,
    appUser,
    isSubscribedTier,
    isEnterpriseTier,
    isUltraTier,
  } = useSession();

  const [clientSecret, setClientSecret] = useState<null | string>(null);

  const [upgradeSubscription] = useUpgradeSubscriptionMutation();

  const initiateStripeRequest = async ({
    lookup_key,
    promotion_code,
  }: {
    lookup_key: string;
    promotion_code?: string;
  }) => {
    try {
      const result = await upgradeSubscription({
        lookup_key: lookup_key,
        user_id: appUser.user_id,
        promotion_code: promotion_code,
        embedded: true,
      }).unwrap();

      if (!!result?.checkout_session?.client_secret) {
        setClientSecret(result.checkout_session.client_secret);
      }
    } catch (error) {
      log.error(error);
    }
  };

  const navigate = useNavigate();

  const handleOpenCheckout = async (type: PlanSectionType) => {
    if (type !== 'Basic') {
      if (selectedPeriod === PaymentPlanInterval.MONTHLY) {
        sendGTMEvent(
          type === 'Pro'
            ? GTMEvent.GET_PRO_MONTHLY_CLICK
            : GTMEvent.GET_ULTRA_MONTHLY_CLICK,
        );
      } else {
        sendGTMEvent(
          type === 'Pro'
            ? GTMEvent.GET_PRO_ANNUAL_CLICK
            : GTMEvent.GET_ULTRA_ANNUAL_CLICK,
        );
      }
    }

    const plan = SUBSCRIPTIONS.find((item) => item.plan === type);
    if (plan) {
      setClientSecret(null);
      setSelectedPlan(plan);
      initiateStripeRequest({
        lookup_key:
          selectedPeriod === PaymentPlanInterval.MONTHLY
            ? plan?.monthly_key || ''
            : plan?.yearly_key || '',
        promotion_code:
          selectedPeriod === PaymentPlanInterval.MONTHLY
            ? plan?.promotion_code
            : undefined,
      });
      onOpenEmbeddedCheckoutVisible();
    }
  };

  const handleCloseCheckout = () => {
    onCloseEmbeddedCheckoutVisible();
    setSelectedPlan(null);
    setClientSecret(null);
  };

  useEffect(() => {
    // setIsLoading(true);
    if (appUser.user_id && isOpenTier) {
      localStorage.setItem(
        REDIRECT_TO_STRIPE_TIMESTAMP,
        dayjs().valueOf().toString(),
      );
      navigate(AppRoutes.LOGIN);
    } else if (
      appUser.user_id &&
      (isUltraTier || isEnterpriseTier || isSubscribedTier)
    ) {
      navigate('/');
    }
    // setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenTier, appUser.user_id, isSubscribedTier, isEnterpriseTier]);

  const handleRemoveFlagFromLS = () => {
    if (!!localStorage.getItem(REDIRECT_TO_STRIPE_TIMESTAMP)) {
      localStorage.removeItem(REDIRECT_TO_STRIPE_TIMESTAMP);
    }
  };

  const handleBackButtonClick = () => {
    sendGTMEvent(GTMEvent.PAYMENT_BACK_BUTTON_CLICK);
    handleRemoveFlagFromLS();
  };

  const handleCompareAllPlanFeaturesClick = () => {
    sendGTMEvent(GTMEvent.PAYMENT_COMPARE_PLAN_FEATURES_CLICK);
  };

  const handlePeriodToggle = (period: PaymentPlanInterval) => {
    setSelectedPeriod(period);

    if (!!selectedPlan) {
      setClientSecret(null);
      initiateStripeRequest({
        lookup_key:
          period === PaymentPlanInterval.MONTHLY
            ? selectedPlan?.monthly_key || ''
            : selectedPlan?.yearly_key || '',
        promotion_code:
          period === PaymentPlanInterval.MONTHLY
            ? selectedPlan?.promotion_code
            : undefined,
      });
    }
  };

  const footerComponent = (
    <div className={styles.footer}>
      <div className={styles.hints}>
        <div className={styles.hintRow}>
          <span className={styles.asteriskSign}>*</span>
          <span>
            Access to external models may be restricted if usage reaches
            excessive levels
          </span>
        </div>
        <div className={styles.hintRow}>
          <NinjaAgentsOutlinedIcon width={SVG_SIZE_M} height={SVG_SIZE_M} />
          <span>
            Ninja LLM: Fine-tuned and powered by Ninja’s infrastructure
          </span>
        </div>
      </div>
      <a
        href={LEARN_MORE_PRICES_LINK}
        target="_blank"
        rel="noreferrer"
        className={styles.comparePlanLink}
        onClick={handleCompareAllPlanFeaturesClick}
      >
        <ArrowSquareOut size={SVG_SIZE_M} />
        <span>Compare all plan feature</span>
      </a>
    </div>
  );

  return isLoading ? (
    <ContentPane>
      <div className={styles.loadingOverlay}>
        <Spinner size={SVG_SIZE_XXL} inline />
        <span className={styles.text}>Processing payment…</span>
      </div>
    </ContentPane>
  ) : (
    <div className={styles.root}>
      <div className={styles.centerPaymentPart}>
        <div className={styles.header}>
          <Link
            to={AppRoutes.HOME}
            className={styles.goBackLink}
            onClick={handleBackButtonClick}
          >
            <ArrowLeft size={SVG_SIZE_L} />
          </Link>
          <h3 className={styles.title}>Select your plan</h3>
        </div>

        <div className={styles.periodSwitchWrapper}>
          <PeriodSwitch
            selectedPeriod={selectedPeriod}
            onChangePeriod={handlePeriodToggle}
          />
        </div>
        <div className={styles.planSectionWrapper}>
          <div className={styles.planWrapper}>
            {PlanSections.map((item) => (
              <PlanSection
                key={item}
                type={item}
                onOpenCheckout={handleOpenCheckout}
                period={selectedPeriod}
                isSelected={selectedPlan?.plan === item}
              />
            ))}
            {isMobile && footerComponent}
          </div>
          {!isMobile && footerComponent}
        </div>
      </div>
      <CheckoutSection
        isOpen={isEmbeddedCheckoutVisible}
        onClose={handleCloseCheckout}
        clientSecret={clientSecret}
      />
    </div>
  );
};
