import { useCallback, useMemo, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import {
  useBreakpoint,
  useColorTheme,
  useSession,
  useVisible,
} from 'src/hooks';
import styles from './CheckoutSection.module.scss';
import { Elements } from '@stripe/react-stripe-js';
import { PanelHeader } from '../PanelHeader';
import { StripeElements } from '../StripeElements';
import { SubscriptionPlan } from 'src/types';
import { LoadingPanel } from '../LoadingPanel';
import { loadStripe } from '@stripe/stripe-js';
import { env } from 'src/env';
import { LoadingOverlay } from '../LoadingOverlay';
import { SummarySection } from '../SummarySection';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { CreateSubscriptionActions, OnApproveActions } from '@paypal/paypal-js';

type CheckoutSectionProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedPlan?: SubscriptionPlan;
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(env.REACT_APP_STRIPE_PK);

export const CheckoutSection = ({
  isOpen,
  onClose,
  selectedPlan,
}: CheckoutSectionProps) => {
  const { isMobile, isTablet } = useBreakpoint();
  const { isDarkTheme } = useColorTheme();
  const checkoutContentRef = useRef<HTMLDivElement>(null);

  const {
    appUser: { user_id },
  } = useSession();

  const [isPaymentProcessing, setPaymentProcessing] = useState<boolean>(false);

  const { isVisible, handleVisibilityRemove, handleVisibilitySet } =
    useVisible(true);

  const animationClassNames = useMemo(() => {
    if (isMobile) {
      return 'nj-animate-fade';
    }
    if (isTablet) {
      return 'nj-animate-horizontal-slow-appearance';
    }
    return 'nj-animate-right-panel-width-reduce';
  }, [isMobile, isTablet]);

  const createPaypalSubscription = useCallback(
    async (
      data: Record<string, unknown>,
      actions: CreateSubscriptionActions,
    ): Promise<string> => {
      if (selectedPlan?.metadata?.paypal) {
        return actions.subscription.create({
          plan_id: selectedPlan?.metadata?.paypal.plan_id,
          custom_id: user_id,
        });
      }
      throw new Error(
        "Missing paypal details in the subscription plan's metadata",
      );
    },
    [selectedPlan?.metadata?.paypal, user_id],
  );

  const onApprovePaypalSubscription = useCallback(
    async (
      data: Record<string, unknown>,
      actions: OnApproveActions,
    ): Promise<void> => {
      // for now copy behaviour from stripe
      window.location.href = `${env.REACT_APP_NINJA_UI_URL}?subscription_succeeded_plan=${selectedPlan?.plan_id}&subscription_succeeded_period=${selectedPlan?.period}`;
    },
    [selectedPlan?.plan_id, selectedPlan?.period],
  );

  const handlePaymentProcessingChange = (value: boolean) => {
    setPaymentProcessing(value);
  };

  return (
    <CSSTransition
      in={isOpen}
      timeout={450}
      classNames={animationClassNames}
      nodeRef={checkoutContentRef}
      onExited={handleVisibilitySet}
      unmountOnExit
    >
      <div className={styles.root} ref={checkoutContentRef}>
        {isPaymentProcessing && (
          <LoadingOverlay label={'Processing payment…'} />
        )}

        {isVisible && <LoadingPanel onClose={onClose} />}

        <PanelHeader onClose={onClose} />

        <div className={styles.mainContainer}>
          <SummarySection currentPlan={selectedPlan} />

          <hr className="divider no-margin" />

          <div className={styles.paymentContainer}>
            <PayPalScriptProvider
              options={{
                clientId: env.REACT_APP_PAYPAL_CLIENT_ID,
                vault: true,
                intent: 'subscription',
              }}
            >
              <PayPalButtons
                createSubscription={createPaypalSubscription}
                onApprove={onApprovePaypalSubscription}
                fundingSource={'paypal'}
                style={{
                  layout: 'vertical',
                  color: 'gold',
                  label: 'paypal',
                  borderRadius: 12,
                  height: 53,
                }}
              />
            </PayPalScriptProvider>
            {stripePromise && (
              <Elements
                stripe={stripePromise}
                options={{
                  mode: 'subscription',
                  appearance: {
                    theme: isDarkTheme ? 'night' : 'stripe',
                    variables: {
                      borderRadius: '12px',
                    },
                  },
                  currency: 'usd',
                  amount: selectedPlan?.total_due_amount,
                }}
              >
                <StripeElements
                  selectedPlan={selectedPlan}
                  onReady={handleVisibilityRemove}
                  onPaymentProcessing={handlePaymentProcessingChange}
                />
              </Elements>
            )}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
