// TODO(olha): add all routes
export enum AppRoutes {
  HOME = '/',
  MANAGE_ACCOUNT = '/manage-account',
  SIGN_UP = '/sign-up',
  LOGIN = '/login',
  PAYMENT = '/payment',
  PAYMENT_BETA = '/payment-beta',
}

export enum UserSettingsNestedRoutes {
  PROFILE = 'profile',
  INTEGRATIONS = 'integrations',
  GENERAL = 'general',
  ACTIVITY = 'activity',
}

export enum BillingSettingsNestedRoutes {
  SUBSCRIPTION = 'subscription',
  BILLING = 'billing',
}

export enum DownloadNestedRoutes {
  DOWNLOAD = 'download-app',
}

export interface QueryParams {
  session_id?: string;
  query?: string;
  conv?: string;
  // temporary until our App is verified - Meta needs a prod url to test login functionality
  meta_verification?: string;
  username?: string;
  code?: string;
  state?: string;
  expired_at?: string;
  email?: string;
  recommendation?: string;
  error?: string;
  error_description?: string;
  subscription_succeeded_plan?: string;
  subscription_succeeded_period?: string;
  ['password-changed']?: string;
}

type Links = {
  route:
    | UserSettingsNestedRoutes
    | BillingSettingsNestedRoutes
    | DownloadNestedRoutes;
  icon: JSX.Element;
  title: string;
  defaultRoute: boolean;
  disabled: boolean;
  visible: boolean;
};

export type Navigation = {
  category: string;
  links: Array<Links>;
};
