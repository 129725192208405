import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';

interface ContactNinjaButtonProps {
  title?: string;
}

export const ContactNinjaButton = ({
  title = 'Contact Ninja',
}: ContactNinjaButtonProps) => {
  const { onToggleVisibleContactNinjaModal } = useContext(SessionContext);

  const handleContactNinjaClick = () => {
    onToggleVisibleContactNinjaModal(true);
  };

  return (
    <button className="nj-upgrade-button" onClick={handleContactNinjaClick}>
      {title}
    </button>
  );
};
