import { IDP } from './api';
import { TaskSkill } from './models/TaskSkill';
import { TaskState } from './models/TaskState';
import { UserTier } from './models/UserTier';

export enum GTMEvent {
  INITIALIZATION_FROM_PORTAL = 'Initialization from portal',
  INITIALIZATION_ACCESS_STATE = 'Initialization access state',
  LOG_OUT = 'Log out',
  NEW_CHAT_INITIATION = 'New chat initiation',
  USER_SENT_QUERY = 'User sent query',
  TASK_EVENT = 'Task event',
  TASK_VIEW_OPENING = 'Task view opening',
  FORGOT_PASSWORD_CLICKS = 'Forgot password clicks',
  UPGRADE_BUTTON_CLICKS = 'Upgrade button clicks',
  GOOGLE_SSO_SIGNUP_CLICKS = 'Google SSO signup clicks',
  FACEBOOK_SSO_SIGNUP_CLICKS = 'Facebook SSO signup clicks',
  APPLE_SSO_SIGNUP_CLICKS = 'Apple SSO signup clicks',
  AMAZON_SSO_SIGNUP_CLICKS = 'Amazon SSO signup clicks',
  MICROSOFT_SSO_SIGNUP_CLICKS = 'Microsoft SSO signup clicks',
  LINKED_IN_SSO_SIGNUP_CLICKS = 'LinkedIn SSO signup clicks',
  EMAIL_LOGIN_SUCCESS = 'Email login success',
  EMAIL_LOGIN_FAILURE = 'Email login failure',
  EMAIL_SIGNUP_CLICKS = 'Email signup clicks',
  EMAIL_SIGNUP_SUCCESS = 'Email signup success',
  EMAIL_SIGNUP_FAILURE = 'Email signup failure',
  START_VIDEO_CLICK = 'Start video click',
  CONSENT_FORM_VIEW = 'Consent form view',
  CONSENT_FORM_CONFIRM = 'Consent form confirm',
  CONSENT_FORM_CANCEL = 'Consent form cancel',
  ONBOARDED = 'Onboarded',
  SHARE_BUTTON = 'Share Button',
  SHARE_FACEBOOK = 'Share Facebook',
  SHARE_LINKEDIN = 'Share LinkedIn',
  SHARE_X = 'Share X',
  SHARE_WHATSAPP = 'Share WhatsApp',
  NINJA_AGENTS_CLICK = 'Ninja Agents Click',
  EXTERNAL_MODELS_CLICK = 'External Models Click',
  NINJA_DEEP_RESEARCH_CLICK = 'Ninja Deep Research Click',
  IMAGE_GENERATORS_SETTINGS_CLICK = 'Image generators settings click',
  // (olha) This event is triggered by any paid subscription (Pro, Ultra etc). We decided to keep the "Pro" in the name to preserve the analytics statistics from the time when we had only one Pro plan.
  SUCCESSFUL_NEW_PRO_SUBSCRIPTION = 'Successful new pro subscription',
  DOWNLOAD_DESKTOP_APP = 'Download desktop App',
  GET_PRO_ANNUAL_CLICK = 'Get Pro Annual Checkout Click',
  GET_PRO_MONTHLY_CLICK = 'Get Pro Monthly Checkout Click',
  GET_ULTRA_ANNUAL_CLICK = 'Get Ultra Annual Checkout  Click',
  GET_ULTRA_MONTHLY_CLICK = 'Get Ultra Monthly Checkout Click',
  PAYMENT_BACK_BUTTON_CLICK = 'Payment Back Button',
  PAYMENT_VIEW_ALL_MODELS_CLICK = 'Payment View All Models',
  PAYMENT_COMPARE_PLAN_FEATURES_CLICK = 'Payment Compare Plan Features',
}

export type GTMInitialVariables = {
  user_id: string;
  user_email: string;
  user_first_name?: string;
  user_last_name?: string;
  tier_id?: UserTier;
  login_type?: IDP;
};

export type GTMVariables = {
  message_id?: string;
  task_id?: string;
  task_state?: TaskState | string;
  task_skill?: TaskSkill | string;
  transaction_id?: string;
  subscription_plan?: string;
  subscription_period?: string;
  [key: string]: string | undefined;
};
