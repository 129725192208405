import { ImageCard } from 'src/types/models/ImageCard';
import { useContext, useMemo } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import {
  ImageGenSettingsDimensionGroups,
  ImageGenSettingsResolutions,
  ModalGalleryType,
} from 'src/types';
import { prepareGeneratedImages } from 'src/utils';
import './ThreadImageGenerationCard.scss';
import { ResearchMediaThumbnail } from '../ThreadResearchCard/components/ResearchMediaThumbnail';
import { ImageGenerationModels, SVG_SIZE_M } from 'src/constants';
import { useSession } from 'src/hooks';
import { SlidersHorizontal, X } from '@phosphor-icons/react';
import { useCollapse } from 'react-collapsed';
import { ImageGenModifyPanel } from 'src/components/FlatAppearance/components/ImageGenModifyPanel';

interface ThreadImageGenerationCardProps {
  imageCard: ImageCard;
  messageOriginalQuery?: string;
  messageCleanQuery?: string;
}

export const ThreadImageGenerationCard = ({
  imageCard,
  messageOriginalQuery,
  messageCleanQuery,
}: ThreadImageGenerationCardProps) => {
  const { appUser } = useSession();
  const { images } = imageCard;
  const { onChangeModalGalleryData } = useContext(SessionContext);
  const imagesArray = useMemo(() => {
    if (!!images && appUser.tier_id) {
      return prepareGeneratedImages(images, appUser.tier_id);
    }
    return [];
  }, [images, appUser.tier_id]);

  const {
    isExpanded: isExpandedImageGenSettings,
    setExpanded,
    getCollapseProps,
  } = useCollapse({
    defaultExpanded: false,
  });

  const handleCloseImageGenSettings = () => {
    setExpanded(false);
  };

  const handleSetCurrentMedia = (index: number) => {
    onChangeModalGalleryData(
      true,
      ModalGalleryType.GENERATED_IMAGES,
      imagesArray,
      index,
      imageCard.task_id,
    );
  };

  const dimensionDescription = useMemo(() => {
    //TODO(olha): it's a temporary workaround until BE sends proper dimension values
    const currentImage = imagesArray[0];
    if (!currentImage) {
      return '';
    }

    const { size, dimension_description } = currentImage;

    if (!dimension_description?.group || !dimension_description?.option) {
      return size;
    }

    const { group, option } = dimension_description;

    const currentOption =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      appUser?.settings?.image_gen_settings?.dimensions?.groups?.[
        group as keyof ImageGenSettingsDimensionGroups
      ]?.[option] as ImageGenSettingsResolutions;

    return `${currentOption?.name || ''} ${currentOption?.aspect_ratio || ''}, ${size}`.trim();
  }, [imagesArray, appUser]);

  return (
    <div className="nj-thread-research-media nj-thread-image-generation-media-wrapper">
      <div className="nj-thread-image-generation-header-wrapper">
        <h6 className="nj-thread-image-generation-header-title">
          <span>Image generation</span>
          <span className="nj-thread-image-generation-header-title--caption">
            {imagesArray ? dimensionDescription : ''}
          </span>
        </h6>
        {isExpandedImageGenSettings ? (
          <button
            type="button"
            className="nj-thread-image-generation-cancel-modify-button"
            onClick={handleCloseImageGenSettings}
          >
            <X size={SVG_SIZE_M} />
            <span>Cancel</span>
          </button>
        ) : (
          <button
            type="button"
            className="nj-thread-image-generation-modify-button"
            onClick={() => {
              setExpanded(true);
            }}
          >
            <SlidersHorizontal size={SVG_SIZE_M} />
            <span>Modify</span>
          </button>
        )}
      </div>

      <div {...getCollapseProps()}>
        <ImageGenModifyPanel
          isOpen={isExpandedImageGenSettings}
          onClose={handleCloseImageGenSettings}
          messageOriginalQuery={messageOriginalQuery}
          messageCleanQuery={messageCleanQuery}
        />
      </div>

      <div className="nj-thread-image-generation-media-wrapper--image-list">
        {imagesArray.map((item, index) => (
          <ResearchMediaThumbnail
            key={item.id}
            thumbnail={item.url}
            title={item.title}
            isVideo={false}
            onClick={() => handleSetCurrentMedia(index)}
            icon={item.icon || undefined}
            status={item.status}
            isPixPro={item.source === ImageGenerationModels.PIX_PRO}
            isDisabled={item.isDisabled}
          />
        ))}
      </div>
    </div>
  );
};
