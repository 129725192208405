import styles from './PromptButton.module.scss';
import { useContext, useRef } from 'react';
import ThreadInputBoxContext, {
  ThreadInputBoxContextType,
} from 'src/contexts/ThreadInputBoxContext';
import { ArrowUUpLeft, Sparkle } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { useBreakpoint, useSession } from 'src/hooks';
import log from 'loglevel';
import { Spinner } from 'src/components/Loading';
import cn from 'classnames';
import { getBaseHeaders } from 'src/store/services/config';
import { bffTasksBaseUrl } from 'src/store/constants';

type PromptButtonProps = {
  isDisabled?: boolean;
};

const textDecoder = new TextDecoder();

export const PromptButton = ({ isDisabled = false }: PromptButtonProps) => {
  const { appUser } = useSession();
  const { isMobile } = useBreakpoint();
  const {
    threadInputBoxValue,
    setTemporaryInputValue,
    setPromptLoading,
    temporaryInputValue,
    setThreadInputBoxValue,
    promptLoading,
  } = useContext<ThreadInputBoxContextType>(ThreadInputBoxContext);
  const undoTriggerRef = useRef(false);

  const updatePrompt = async (response: Response, stream: boolean) => {
    let value = '';
    if (stream) {
      if (undoTriggerRef.current) {
        undoTriggerRef.current = false;
        return;
      }
      if (response.body) {
        if (response.body) {
          const reader = response.body.getReader();

          while (true) {
            const { done, value: chunk } = await reader.read();

            if (done) {
              break;
            }

            value += textDecoder.decode(chunk, { stream: true });

            if (undoTriggerRef.current) {
              undoTriggerRef.current = false;
              return;
            }

            setThreadInputBoxValue(value);
          }
        }
      }
    } else {
      const result = await response.json();
      setThreadInputBoxValue(result.improved_prompt);
    }
  };

  const handleSendPrompt = async () => {
    try {
      setPromptLoading(true);
      setTemporaryInputValue(threadInputBoxValue);
      setThreadInputBoxValue('');

      await fetch(
        `${bffTasksBaseUrl}/users/${appUser.user_id}/prompts/improve`,
        {
          method: 'POST',
          headers: await getBaseHeaders(
            new Headers({
              'Content-Type': 'application/json',
            }),
          ),
          body: JSON.stringify({ prompt: threadInputBoxValue, stream: true }),
        },
      )
        .then((response) => updatePrompt(response, true))
        .catch((err) => log.error(err));
      setPromptLoading(false);
    } catch (e) {
      if (undoTriggerRef.current) {
        undoTriggerRef.current = false;
      }
      setPromptLoading(false);
      setThreadInputBoxValue(temporaryInputValue);
      setTemporaryInputValue('');
      log.error(e);
    }
  };

  const handleUndo = () => {
    if (promptLoading) {
      undoTriggerRef.current = true;
    }
    setPromptLoading(false);
    setThreadInputBoxValue(temporaryInputValue);
    setTemporaryInputValue('');
  };

  return promptLoading || !!temporaryInputValue ? (
    <button
      className={cn(styles.improvePromptButton, {
        [styles.iconButton]: !promptLoading && isMobile,
      })}
      type="button"
      onClick={handleUndo}
    >
      {promptLoading ? (
        <Spinner size={SVG_SIZE_M} inline />
      ) : (
        <ArrowUUpLeft size={SVG_SIZE_M} />
      )}

      {!isMobile && !promptLoading && <span>Undo</span>}
      {promptLoading && <span>Cancel</span>}
    </button>
  ) : (
    <button
      className={styles.improvePromptButton}
      type="button"
      disabled={isDisabled || !threadInputBoxValue.trim()}
      onClick={handleSendPrompt}
    >
      <Sparkle size={SVG_SIZE_M} />
      <span>{isMobile ? 'Improve' : 'Improve Prompt'}</span>
    </button>
  );
};
