import { ChatGPTLogo } from 'src/images/logos/chat-gpt';
import { AnthropicLogo } from 'src/images/logos/anthropic-logo';
import { Icon } from 'src/components/Icon';
import { Image } from '@phosphor-icons/react';
import { NinjaAgentsOutlinedIcon } from 'src/images/icons/ninjaAgentsOutlined';

export const WELCOME_UPSELL_MODAL_FEATURES = [
  { title: '1080p (Full HD) image generation' },
  { title: '11 image aspect ratios' },
  { title: 'File upload analysis up to ~150k words' },
  { title: 'Advanced real-time research' },
  { title: 'Multi-party meeting scheduling' },
];

export const WELCOME_UPSELL_MODAL_ULTRA_FEATURES = [
  { title: '2k (Quad HD) image generation' },
  { title: 'Advanced AI image editing (coming soon)' },
  { title: 'Advanced coding (coming soon)' },
  { title: 'File upload analysis up to ~750k words' },
];

export const EXTERNAL_MODELS_LIST = [
  {
    title: 'Fine-tuned Llama 3.1 405b',
    icon: <NinjaAgentsOutlinedIcon width={20} height={24} />,
  },
  {
    title: (
      <>
        <span>Ninja PixArt-Sigma</span>
        <Image size={20} />
      </>
    ),
    icon: <NinjaAgentsOutlinedIcon width={20} height={24} />,
  },
  {
    title: (
      <>
        <span>Ninja Flux.1</span>
        <Image size={20} />
      </>
    ),
    icon: <NinjaAgentsOutlinedIcon width={20} height={24} />,
  },
  {
    title: 'OpenAI GPT-4o Mini',
    icon: <ChatGPTLogo />,
  },
  {
    title: 'Anthropic Claude 3.5 Sonnet',
    icon: <AnthropicLogo />,
  },
  {
    title: 'Google Gemini 1.5 Flash',
    icon: <Icon type="googleGeminiProLogo" size={18} />,
  },
];

export const EXTERNAL_MODELS_ULTRA_LIST = [
  {
    title: 'Fine-tuned Llama 3.1 405b',
    icon: <NinjaAgentsOutlinedIcon width={20} height={24} />,
  },
  {
    title: (
      <>
        <span>Ninja PixArt-Sigma</span>
        <Image size={20} />
      </>
    ),
    icon: <NinjaAgentsOutlinedIcon width={20} height={24} />,
  },
  {
    title: (
      <>
        <span>Ninja Flux.1</span>
        <Image size={20} />
      </>
    ),
    icon: <NinjaAgentsOutlinedIcon width={20} height={24} />,
  },
  {
    title: 'OpenAI GPT-4o',
    icon: <ChatGPTLogo />,
  },
  {
    title: 'Anthropic Claude 3 Opus',
    icon: <AnthropicLogo />,
  },
  {
    title: 'Google Gemini 1.5 Pro',
    icon: <Icon type="googleGeminiProLogo" size={18} />,
  },
];
