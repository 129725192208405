import { useContext, useCallback } from 'react';
import dayjs from 'dayjs';
import DebugContext from 'src/contexts/DebugContext';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import AudioContext from 'src/contexts/AudioContext';
import {
  useSession,
  useFetchUserInput,
  useUserLocation,
  useConversationParams,
  useRecurringUpsellModal,
} from 'src/hooks';
import {
  ConversationRole,
  MessageChannel,
  ChatMode,
  Message,
  Tag,
  AvatarAnimations,
  LocationSource,
} from 'src/types';
import {
  useAppDispatch,
  useAppSelector,
  sessionState,
  setRecurringUpsellModalControls,
} from 'src/store';
import { animateMetahuman } from 'src/utils';
import { DEFAULT_CHAT_ID } from 'src/constants';

export const useSubmitUserInput = () => {
  const { debugMode } = useContext(DebugContext);
  const {
    setThreadInputBoxValue,
    deleteAttachment,
    fileDataToSend,
    setTemporaryInputValue,
  } = useContext(ThreadInputBoxContext);
  const { threadMessageListAnchorRef } = useContext(ForwardRefContext);
  const { setMetaHumanTalking } = useContext(AudioContext);

  const {
    agent,
    chatMode,
    appUser,
    isFirstUserQuery,
    updateFirstUserQuery,
    isEnterpriseTier,
    isSubscribedTier,
  } = useSession();
  const { fetchAiData } = useFetchUserInput();
  const { setLocationInterval } = useUserLocation();
  const { currentConversationId } = useConversationParams();

  const { setNewUpsellTimestamp } = useRecurringUpsellModal();

  const dispatch = useAppDispatch();
  const {
    recurringUpsellModalControls: { nextTimestamp },
  } = useAppSelector(sessionState);

  const isAvatarMode = chatMode === ChatMode.AVATAR;

  const handleUpdateUserLocation = useCallback(() => {
    if (!isFirstUserQuery && appUser.location?.source !== LocationSource.GPS) {
      setLocationInterval();
      updateFirstUserQuery();
    }
  }, [isFirstUserQuery, appUser, updateFirstUserQuery, setLocationInterval]);

  const handleShowUpsellModal = useCallback(() => {
    if (isEnterpriseTier || isSubscribedTier) {
      return;
    }

    if (!nextTimestamp) {
      setNewUpsellTimestamp();
      return;
    }

    if (dayjs().valueOf() < nextTimestamp) {
      return;
    }

    dispatch(
      setRecurringUpsellModalControls({
        isVisible: true,
      }),
    );
  }, [
    dispatch,
    nextTimestamp,
    isEnterpriseTier,
    isSubscribedTier,
    setNewUpsellTimestamp,
  ]);

  const onSubmitUserInput = useCallback(
    async (userInput: string, params?: Partial<Message>) => {
      if (!userInput.trim()) {
        return;
      }

      handleUpdateUserLocation();

      if (isAvatarMode) {
        animateMetahuman(appUser.user_id, AvatarAnimations.THINKING, debugMode);
      }

      const newMessage = {
        user_id: appUser.user_id,
        from_user_id: appUser.user_id,
        to_user_id: agent.user_id,
        channel: MessageChannel.WEB_APP,
        role: ConversationRole.USER,
        content:
          fileDataToSend.length > 0
            ? `${fileDataToSend} ${userInput}`
            : userInput,
        is_read: false,
        tag: Tag.CONVERSATION,
        timestamp: new Date().toISOString(),
        conversation_id: currentConversationId || DEFAULT_CHAT_ID,
        persona: appUser.persona,
        ...(params ? params : {}),
      } as Message;

      setTemporaryInputValue('');
      setThreadInputBoxValue('');
      deleteAttachment();

      threadMessageListAnchorRef?.current?.onScrollToBottomMessageList(0);

      await fetchAiData(newMessage);

      if (isAvatarMode) {
        // TODO(olha): it's a workaround until we have "beginSpeech" event from the Avatar game
        setMetaHumanTalking(true);
      }

      handleShowUpsellModal();
    },
    [
      appUser,
      agent.user_id,
      currentConversationId,
      debugMode,
      isAvatarMode,
      threadMessageListAnchorRef,
      setMetaHumanTalking,
      handleUpdateUserLocation,
      fetchAiData,
      setThreadInputBoxValue,
      setTemporaryInputValue,
      deleteAttachment,
      handleShowUpsellModal,
      fileDataToSend,
    ],
  );

  return { onSubmitUserInput };
};
