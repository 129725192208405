import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetState } from 'react-use';
import { DimensionForm } from 'src/components/ImageGenSettings/components/DimensionForm';
import {
  AppRoutes,
  ImageGenSettings,
  ImageGenSettingsSelectedDimension,
  ImageGenStyles,
} from 'src/types';
import { toast } from 'react-toastify';
import { ImageStyleSelect } from 'src/components/ImageGenSettings/components/ImageStyleSelect';
import styles from './ImageGenModifyPanel.module.scss';
import { useSession, useSubmitUserInput, useUserData } from 'src/hooks';
import { ImagesModelSelector } from 'src/components/ImageGenSettings/components/ImagesModelSelector';
import { ImageGenerationModelOptions, ImageGenerationModels } from 'src/constants';
import { ImagesPerModelToggle } from 'src/components/ImageGenSettings/components/ImagesPerModelToggle';

type ImageGenModifyPanelProps = {
  isOpen?: boolean;
  onClose: () => void;
  messageOriginalQuery?: string;
  messageCleanQuery?: string;
};

type ModifiedData = {
  dimensionValues?: ImageGenSettingsSelectedDimension;
  imageStyle?: ImageGenStyles;
  imageModel?: ImageGenerationModelOptions;
  imagesPerModel?: number;
};

export const ImageGenModifyPanel = ({
  isOpen,
  onClose,
  messageOriginalQuery = '',
  messageCleanQuery = '',
}: ImageGenModifyPanelProps) => {
  const navigate = useNavigate();

  const {
    appUser: { settings },
    isOpenTier,
    isFreeTier,
  } = useSession();

  const { updateUserSettings } = useUserData();
  const { onSubmitUserInput } = useSubmitUserInput();

  // TODO(olha): temporary hide additional changes field
  // const [additionalChanges, setAdditionalChanges] = useState<string>('');

  const dimensionValues = settings?.image_gen_settings?.dimensions?.selected;
  const imageStyle = settings?.image_gen_settings?.style?.value;

  // TODO: duplicated code in NinjaAgentsSection.tsx
  const imageModel = useMemo(() => {
    const pixModel = (settings?.image_gen_settings?.models || []).find(
      (item) => item.model === ImageGenerationModels.PIX,
    );
    const pixProModel = (
      settings?.image_gen_settings?.models || []
    ).find((item) => item.model === ImageGenerationModels.PIX_PRO);

    const pixModelEnabled = !!pixModel?.enabled
    const pixProModelEnabled = !!pixProModel?.enabled

    if (pixModelEnabled && pixProModelEnabled) return ImageGenerationModelOptions.BOTH;
    if (pixModelEnabled) return ImageGenerationModelOptions.PIX
    if (pixProModelEnabled) return ImageGenerationModelOptions.PIX_PRO
  }, [settings?.image_gen_settings?.models]);

  // TODO: duplicated code in NinjaAgentsSection.tsx
  const imagesPerModel = useMemo(() => {
    const pixModel = (settings?.image_gen_settings?.models || []).find(
      (item) => item.model === ImageGenerationModels.PIX,
    );
    const pixProModel = (
      settings?.image_gen_settings?.models || []
    ).find((item) => item.model === ImageGenerationModels.PIX_PRO);

    return (pixModel?.enabled ? pixModel?.num_images : pixProModel?.num_images) || 1;
  }, [settings?.image_gen_settings?.models]);


  const defaultSettings = {
    dimensionValues,
    imageStyle,
    imagesPerModel,
    imageModel,
  };

  const [imageSettings, setImageSettings] =
    useSetState<ModifiedData>(defaultSettings);

  useEffect(() => {
    if (!isOpen) {
      setImageSettings(defaultSettings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    setImageSettings({ dimensionValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensionValues]);

  useEffect(() => {
    setImageSettings({ imageStyle });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageStyle]);

  useEffect(() => {
    setImageSettings({ imageModel });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageModel])

  useEffect(() => {
    setImageSettings({ imagesPerModel });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagesPerModel])

  // const handleChangeAdditionalChanges = (e: ChangeEvent<HTMLInputElement>) => {
  //   setAdditionalChanges(e.target.value);
  // };

  const handleDimensionChange = (
    dimensionValues: ImageGenSettingsSelectedDimension,
  ) => {
    setImageSettings({ dimensionValues });
  };

  const handleStyleChange = (imageStyle: ImageGenStyles) => {
    setImageSettings({ imageStyle });
  };

  const handleModelChange = async (imageModel: ImageGenerationModelOptions) => {
    setImageSettings({ imageModel });
  };

  const handleImagesPerModelChange = async (imagesPerModel: number) => {
    setImageSettings({ imagesPerModel });
  };

  const handleSettingsUpdate = async () => {
    const updatedModels = (settings?.image_gen_settings?.models || []).map(
      (item) => {
        return Object.values(ImageGenerationModels).includes(
          item.model as ImageGenerationModels,
        )
          ? {
              ...item,
              enabled:
                imageSettings.imageModel === item.model ||
                imageSettings.imageModel ===
                ImageGenerationModelOptions.BOTH,
              num_images: imageSettings.imagesPerModel,
            }
          : item;
      },
    );

    const image_gen_settings: ImageGenSettings = {
      ...(settings?.image_gen_settings || {}),
      style: {
        value: imageSettings.imageStyle,
      },
      dimensions: {
        ...(settings?.image_gen_settings?.dimensions || {}),
        selected: imageSettings.dimensionValues,
      },
      models: updatedModels
    };

    await updateUserSettings({ image_gen_settings });
  };

  const handleRegenerateClick = async () => {
    if (isOpenTier) {
      navigate(AppRoutes.SIGN_UP);
      return;
    }
    if (isFreeTier) {
      navigate(AppRoutes.PAYMENT);
      return;
    }

    try {
      const value =
        `/image ${messageCleanQuery || messageOriginalQuery}`.trim();
      // additionalChanges.length > 0
      //   ? `Starting from "${messageOriginalQuery}", ${additionalChanges}`
      //   : messageOriginalQuery;

      await handleSettingsUpdate();

      onSubmitUserInput(value);
    } catch (e) {
      setImageSettings(defaultSettings);
      toast('Something went wrong. Try again later');
    } finally {
      // setAdditionalChanges('');
      onClose();
    }
  };

  const submitTitle = useMemo(() => {
    if (isOpenTier) {
      return 'Sign up to unlock';
    }
    if (isFreeTier) {
      return 'Upgrade to unlock';
    }
    return 'Regenerate';
  }, [isOpenTier, isFreeTier]);

  const buttonId = isFreeTier
    ? 'Image-modify-upgrade'
    : isOpenTier
      ? 'Image-modify-signup'
      : '';

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.field}>
          <DimensionForm
            onChange={handleDimensionChange}
            selectedData={imageSettings.dimensionValues}
          />

          <hr className="divider" />

          <ImageStyleSelect
            onChange={handleStyleChange}
            selectedValue={imageSettings.imageStyle}
            maxMenuHeight={200}
          />

          <ImagesModelSelector
            selectedValue={imageSettings.imageModel}
           onChange={handleModelChange}
          />

          <ImagesPerModelToggle
            selectedValue={imageSettings.imagesPerModel}
            onChange={handleImagesPerModelChange}
          />

          {/* <label className={styles.label}>Additional changes</label>
          <input
            type="text"
            className={styles.input}
            placeholder="What changes would you like to make?"
            value={additionalChanges}
            onChange={handleChangeAdditionalChanges}
            disabled={isDisabled}
          /> */}
        </div>
        <button
          className={styles.regenerateButton}
          type="button"
          onClick={handleRegenerateClick}
          id={buttonId}
        >
          {submitTitle}
        </button>
      </div>
    </div>
  );
};
