import styles from './UpsellMessageBlock.module.scss';
import { GuestAuthLinks } from 'src/pages/AuthPages/components/GuestAuthLinks';
import { UpgradeLearnMoreButtons } from 'src/pages/AuthPages/components/UpgradeLearnMoreButtons';
import { useSession } from 'src/hooks';
import { useMemo } from 'react';

export const UpsellMessageBlock = () => {
  const { isOpenTier, isProTrialTier, isFreeTier } = useSession();
  const title = useMemo(() => {
    if (isOpenTier) {
      return 'Ninja Pro Trial for free';
    }
    if (isProTrialTier || isFreeTier) {
      return 'Upgrade to Ninja Pro';
    }

    return '';
  }, [isOpenTier, isProTrialTier, isFreeTier]);

  const description = useMemo(() => {
    if (isOpenTier) {
      return 'Enjoy 7 days of unlimited access to Ninja Agents. No credit card needed.';
    }
    if (isProTrialTier || isFreeTier) {
      return 'Get unlimited tasks, and gain access to advanced features with Ninja Pro or Ultra.';
    }

    return '';
  }, [isOpenTier, isProTrialTier, isFreeTier]);

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div>
          <div className={styles.titleContainer}>
            <h4 className={styles.title}>{title}</h4>
            {isProTrialTier && <span className={styles.tag}>Trial Offer</span>}
            {isOpenTier && <span className={styles.tag}>Limited time</span>}
          </div>
          <p className={styles.description}>{description}</p>
        </div>
        <div className={styles.buttonWrapper}>
          {isOpenTier && <GuestAuthLinks dataGTM="Chat-thread-signup" />}
          {(isProTrialTier || isFreeTier) && (
            <UpgradeLearnMoreButtons dataGTM="Chat-thread-upgrade" />
          )}
        </div>
      </div>
    </div>
  );
};
