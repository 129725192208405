import dayjs from 'dayjs';
import { PAYMENTS_DATE_FORMAT } from 'src/constants';
import { useSession } from 'src/hooks';
import { LabelPro } from 'src/components/LabelPro';
import { UpgradeButton } from 'src/components/UpgradeButton';
import './SubscriptionPlansForm.scss';
import { useGetUserSubscriptionInfoQuery } from 'src/store/services';
import { LabelFree } from 'src/components/LabelFree';
import { LabelProTrial } from 'src/components/FlatAppearance/components/LabelProTrial';
import { LabelEnterpriseTrial } from 'src/components/LabelEnterpriseTrial';
import { ContactNinjaButton } from 'src/components/ContactNinjaButton';
import { LabelUltra } from 'src/components/LabelUltra';

// TODO(olha): divide to small components
export const SubscriptionPlansForm = () => {
  const {
    isProTier,
    appUser,
    isProTrialTier,
    isFreeTier,
    isEnterpriseTier,
    isSubscribedTier,
    isUltraTier,
  } = useSession();
  const { data, isLoading } = useGetUserSubscriptionInfoQuery(
    {
      user_id: appUser.user_id,
    },
    { skip: !isSubscribedTier },
  );

  if (isLoading) {
    return null;
  }

  const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: data?.price_info?.currency || 'USD',
  });

  const amountInfo = moneyFormatter.format(data?.price_info?.amount || 0);

  const cancelledDate = dayjs(data?.curr_period_end_timestamp).format(
    PAYMENTS_DATE_FORMAT,
  );

  const upcomingPayment = data?.is_cancelled
    ? 'None'
    : `${amountInfo} on ${cancelledDate}`;

  return (
    <div className="nj-subscription-plans-form">
      <div className="nj-subscription-plans-form--container">
        <div className="nj-subscription-plans-form--header-wrapper">
          <p className="nj-subscription-plans-form--header">
            <span className="nj-subscription-plans-form--title">
              Subscription
            </span>

            <span className="nj-subscription-plans-form--description">
              {/*todo temporary hided till upgrade plan is implemented*/}
              {/*{isSubscribedTier &&*/}
              {/*  'Edit or cancel your subscription. Choose between monthly and yearly billing. All yearly billing plans will receive a 30% discount.'}*/}
              {isFreeTier &&
                'Get unlimited access to Ninja’s AI Assistant and all Pro features. Cancel anytime.'}
              {isProTrialTier &&
                'Continue enjoying Pro features - Upgrade now!'}
              {isEnterpriseTier &&
                'Continue enjoying Enterprise features - Upgrade now!'}
            </span>
          </p>
        </div>

        <div className="nj-subscription-plans-form--field">
          <p className="nj-subscription-plans-form--label">Plan</p>

          {isEnterpriseTier && (
            <p className="nj-subscription-plans-form--value">
              <LabelEnterpriseTrial />
            </p>
          )}
          {(isProTrialTier || isSubscribedTier) && (
            <div className="nj-subscription-plans-form--value-wrapper">
              {isProTrialTier && <LabelProTrial />}
              {isProTier && <LabelPro labelType="full" />}
              {isUltraTier && <LabelUltra labelType="full" />}
              {data && data?.is_cancelled && (
                <div className="nj-subscription-plans-form--cancelled-label">
                  Plan will be canceled on &nbsp;
                  <span className="nj-subscription-plans-form--cancelled-date">
                    {cancelledDate}
                  </span>
                </div>
              )}
            </div>
          )}
          {isFreeTier && (
            <p className="nj-subscription-plans-form--value">
              <LabelFree />
            </p>
          )}
        </div>

        {isSubscribedTier && (
          <>
            <div className="nj-subscription-plans-form--field">
              <p className="nj-subscription-plans-form--label">Billing cycle</p>
              <p className="nj-subscription-plans-form--value capitalize">
                {data?.interval}
              </p>
            </div>

            <div className="nj-subscription-plans-form--field">
              <p className="nj-subscription-plans-form--label">
                Upcoming Payment
              </p>
              <p className="nj-subscription-plans-form--value">
                {upcomingPayment}
              </p>
            </div>
          </>
        )}
      </div>

      {!isSubscribedTier && !isEnterpriseTier && (
        <UpgradeButton title="Upgrade plan" dataGTM="Subscription-upgrade" />
      )}
      {(isEnterpriseTier || isProTier) && (
        <ContactNinjaButton title="Upgrade plan" />
      )}
    </div>
  );
};
