import { FormEvent } from 'react';
import {
  ExpressCheckoutElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { datadogLogs } from '@datadog/browser-logs';
import styles from './StripeElements.module.scss';
import {
  StripeExpressCheckoutElementConfirmEvent,
  StripePaymentElementChangeEvent,
} from '@stripe/stripe-js';
import { useSession, useVisible } from 'src/hooks';
import { useCreateSubscriptionMutation } from 'src/store/services';
import { env } from 'src/env';
import { toast } from 'react-toastify';
import { TRANSACTION_ID } from 'src/constants';
import { SubscriptionPlan } from 'src/types';

const ERROR_MESSAGE =
  'We’re having trouble processing your payment. Please check your information or try a different payment method.';

const DATADOG_ERROR = 'Payment failed';
const PAYMENT_PROVIDER = 'stripe';

interface StripeElementsProps {
  selectedPlan?: SubscriptionPlan;
  onReady: () => void;
  onPaymentProcessing: (value: boolean) => void;
}

export const StripeElements = ({
  selectedPlan,
  onReady,
  onPaymentProcessing,
}: StripeElementsProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const {
    appUser: { user_id },
  } = useSession();

  const [createSubscription] = useCreateSubscriptionMutation();

  const {
    isVisible: isSubmitDisabled,
    handleVisibilitySet: setSubmitDisabled,
    handleVisibilityRemove: setSubmitEnabled,
  } = useVisible(true);

  const handleCreateSubscription = async () => {
    if (!selectedPlan) return;
    try {
      const result = await createSubscription({
        user_id,
        // TODO(olha): BE is not ready for getting new lookupKey
        plan_id: selectedPlan?.plan_id,
      }).unwrap();

      return result;
    } catch (error) {
      onPaymentProcessing(false);
      toast(ERROR_MESSAGE);

      datadogLogs.logger.error(DATADOG_ERROR, {
        user_id,
        payment_provider: PAYMENT_PROVIDER,
        plan: selectedPlan?.plan_id,
        step: 'create subscription',
        error: JSON.stringify(error),
      });

      return;
    }
  };

  const handleConfirmPayment = async () => {
    if (!elements || !stripe) {
      return;
    }

    const subscriptionIntent = await handleCreateSubscription();

    if (!subscriptionIntent) {
      return;
    }

    const { client_secret, subscription_id } = subscriptionIntent;

    localStorage.setItem(TRANSACTION_ID, subscription_id);

    const returnUrl = `${env.REACT_APP_NINJA_UI_URL}?subscription_succeeded_plan=${selectedPlan?.plan_id}&subscription_succeeded_period=${selectedPlan?.period}`;

    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret: client_secret,
      confirmParams: {
        return_url: returnUrl,
      },
    });

    if (error) {
      localStorage.removeItem(TRANSACTION_ID);
      onPaymentProcessing(false);
      toast(ERROR_MESSAGE);

      datadogLogs.logger.error(DATADOG_ERROR, {
        user_id,
        payment_provider: PAYMENT_PROVIDER,
        plan: selectedPlan?.plan_id,
        step: 'confirm payment',
        error: JSON.stringify(error),
      });
      console.error(error);
    }
  };

  const handleSubmit = async (
    event:
      | StripeExpressCheckoutElementConfirmEvent
      | FormEvent<HTMLFormElement>,
  ) => {
    if ('preventDefault' in event) {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();
    }

    if (!elements || !stripe) {
      return;
    }

    onPaymentProcessing(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      onPaymentProcessing(false);

      datadogLogs.logger.error(DATADOG_ERROR, {
        user_id,
        payment_provider: PAYMENT_PROVIDER,
        plan: selectedPlan?.plan_id,
        step: 'stripe form validation',
        error: JSON.stringify(submitError),
      });

      return;
    }

    await handleConfirmPayment();
  };

  const handlePaymentElementChange = ({
    complete,
  }: StripePaymentElementChangeEvent) => {
    if (complete) {
      setSubmitEnabled();
    } else {
      setSubmitDisabled();
    }
  };

  return (
    <form className={styles.root} onSubmit={handleSubmit}>
      <ExpressCheckoutElement
        options={{
          paymentMethods: {
            googlePay: 'always',
            amazonPay: 'never', // never so it's shown in payment element below
            link: 'never', // never so it's shown in payment element below
            paypal: 'never',
          },
          buttonTheme: {
            googlePay: 'white',
            applePay: 'white',
          },
          layout: {
            maxColumns: 1,
            maxRows: 2,
          },
          buttonHeight: 53,
        }}
        onConfirm={handleSubmit}
      />
      <PaymentElement
        options={{
          layout: {
            type: 'accordion',
            defaultCollapsed: true,
            radios: false,
            spacedAccordionItems: true,
          },
          paymentMethodOrder: [
            'google_pay',
            'apple_pay',
            'card',
            'cashapp',
            'amazon_pay',
          ],
        }}
        onReady={onReady}
        onChange={handlePaymentElementChange}
      />
      <button
        disabled={isSubmitDisabled}
        className={styles.submitButton}
        type="submit"
      >
        Subscribe
      </button>
    </form>
  );
};
