import { usersApi } from 'src/store/services';
import { AppUser, UserSettings } from 'src/types';
import { useSession } from 'src/hooks';
import { toast } from 'react-toastify';

const SAVED_TEXT = 'Saved';
const TOAST_ID = 'updateUserDataToast';

export const useUserData = () => {
  const { appUser, updateAppUser } = useSession();

  const [updateUserMutation] = usersApi.useUpdateUserMutation();

  const updateUserData = async (
    data: Partial<AppUser>,
    showNotificationToast = true,
    notificationText?: string,
  ) => {
    const request = {
      id: appUser.user_id,
      body: {
        ...data,
      },
    };

    await updateUserMutation(request).unwrap();
    // update session
    updateAppUser({
      ...appUser,
      ...data,
    });
    // display toast
    if (showNotificationToast) {
      if (!toast.isActive(TOAST_ID)) {
        toast(notificationText || SAVED_TEXT, { toastId: TOAST_ID });
      }
    }
  };

  const updateUserSettings = async (
    data: Partial<UserSettings>,
    notificationText?: string,
  ) => {
    await updateUserData(
      {
        settings: {
          ...appUser.settings,
          ...data,
        },
      },
      true,
      notificationText,
    );
  };

  return {
    updateUserData,
    updateUserSettings,
  };
};
