declare global {
  interface Window {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    env: any;
  }
}

type EnvType = {
  REACT_APP_COLOR: string;
  REACT_APP_MAIN_TEXT: string;
  REACT_APP_LINK_URL: string;
  REACT_APP_LOGO_URL: string;
  NODE_ENV: string;
  REACT_APP_AVATAR_ENV: string;
  REACT_APP_ENVIRONMENT: string;
  REACT_APP_NINJA_UI_URL: string;
  REACT_APP_NINJA_API_V2_URL: string;
  REACT_APP_NINJA_FEEDBACK_API_URL: string;
  REACT_APP_E_ASSISTANT: string;
  REACT_APP_AVATAR_MATCHMAKER_SERVER: string;
  REACT_APP_AVATAR_FALLBACK: string;
  REACT_APP_CONV_ENDPOINT: string;
  REACT_APP_CONTACTS_ENDPOINT: string;
  REACT_APP_AVATARS_S3_BUCKET: string;
  REACT_APP_COMBUS_ENDPOINT: string;
  REACT_APP_COMBUS_WS_ENDPOINT: string;
  REACT_APP_DEBUG: boolean;
  REACT_APP_COGNITO_REGION: string;
  REACT_APP_COGNITO_OATH_DOMAIN: string;
  REACT_APP_COGNITO_USER_POOL_ID: string;
  REACT_APP_COGNITO_USER_POOL_CLIENT_ID: string;
  REACT_APP_DATADOG_APP_ID: string;
  REACT_APP_DATADOG_CLIENT_TOKEN: string;
  REACT_APP_DATADOG_SITE: string;
  REACT_APP_DATADOG_RUM_SESSION_SAMPLE_RATE: string;
  REACT_APP_DATADOG_RUM_REPLAY_SAMPLE_RATE: string;
  REACT_APP_DATADOG_LOGS_SAMPLE_RATE: string;
  REACT_APP_GOOGLE_AUTHORIZATION_REDIRECT_URI: string;
  REACT_APP_GOOGLE_CLIENT_ID: string;
  REACT_APP_META_HUMAN_OFFLINE_RENDER_URL: string;
  REACT_APP_NINJA_TOKEN_API_URL: string;
  REACT_APP_S3_BUCKET: string;
  REACT_APP_META_HUMAN_BASE_URL: string;
  REACT_APP_ZENDESK_TICKETS_API: string;
  REACT_APP_ZENDESK_TOKEN: string;
  REACT_APP_ZENDESK_BASIC_AUTH: string;
  REACT_APP_GOOGLE_TAG_MANAGER_ID: string;
  REACT_APP_TEMPORARY_PASSWORD: string;
  REACT_APP_NINJA_WALLET_API_URL: string;
  REACT_APP_FACEBOOK_PIXEL_ID: string;
  REACT_APP_NINJA_CONTENT_API_URL: string;
  REACT_APP_STRIPE_PK: string;
  REACT_APP_BFF_URL: string;
  REACT_APP_PAYPAL_CLIENT_ID: string;
};
export const env: EnvType = { ...process.env, ...window.env };
