import { Modal } from 'src/components/Modal';
import { SVG_SIZE_L, SVG_SIZE_M } from 'src/constants';
import { ArrowSquareOut, XCircle } from '@phosphor-icons/react';
import {
  DEFAULT_NINJATECH_LINK,
  WELCOME_PRO_TRIAL_IMAGE,
} from 'src/constants/externalLinks';
import { useBreakpoint } from 'src/hooks';
import { useGetUpsellModalsConfigQuery } from 'src/store/services';
import { PlanFeatureItem } from '../PlanCardModal/components/PlanFeatureItem';
import {
  WELCOME_UPSELL_MODAL_FEATURES,
  EXTERNAL_MODELS_LIST,
  WELCOME_UPSELL_MODAL_ULTRA_FEATURES,
  EXTERNAL_MODELS_ULTRA_LIST,
} from './constants';
import { AppRoutes, WelcomeUpsellModalType } from 'src/types';
import styles from './WelcomeUpsellModal.module.scss';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

interface WelcomeUpsellModalProps {
  modalType: WelcomeUpsellModalType;
  isOpen: boolean;
  onClose: () => void;
  onContinue?: () => void;
  onUpgrade?: () => void;
}

export const WelcomeUpsellModal = ({
  modalType,
  isOpen,
  onClose,
  onContinue,
  onUpgrade,
}: WelcomeUpsellModalProps) => {
  const { isMobile } = useBreakpoint();
  const navigate = useNavigate();

  const isEnterpriseModal = modalType === 'enterprise-tier-welcome-modal';

  const isSubscribedModal =
    modalType === 'ultra-welcome-modal' || modalType === 'pro-welcome-modal';

  const isUltraModal =
    modalType === 'ultra-welcome-modal' ||
    modalType === 'enterprise-tier-welcome-modal';

  const { data } = useGetUpsellModalsConfigQuery();

  const welcomeUpsellData = (data || {})?.[modalType];

  if (!welcomeUpsellData) {
    return null;
  }

  const {
    title,
    description,
    continueButtonTitle,
    upgradeButtonId,
    imageURL,
    isContinueButtonBlue,
    isUpgradeButtonBlue,
    reverseButtonsOrder,
  } = welcomeUpsellData;

  const handleContinueClick = () => {
    onClose();

    if (onContinue) {
      onContinue();
    }
  };

  const handleUpgradeClick = async () => {
    navigate(AppRoutes.PAYMENT);
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      className={styles.welcomeProTrialModal}
      overlayClassName={styles.overlay}
      showCloseIcon={false}
      blockScroll={false}
    >
      <div className={styles.root}>
        <button type="button" className={styles.buttonClose} onClick={onClose}>
          <XCircle size={SVG_SIZE_L} weight="fill" />
        </button>

        {!isMobile && (
          <img
            src={imageURL || WELCOME_PRO_TRIAL_IMAGE}
            alt="Ninja"
            className={styles.image}
          />
        )}

        <div className={styles.main}>
          <div className={styles.titleWrapper}>
            <h2 className={styles.title}>{title}</h2>

            <h4 className={styles.subtitle}>{description}</h4>
          </div>

          <div className={styles.featuresBlock}>
            <div className={styles.featuresWrapper}>
              <ul className={styles.leftSideFeatures}>
                {(isUltraModal
                  ? WELCOME_UPSELL_MODAL_ULTRA_FEATURES
                  : WELCOME_UPSELL_MODAL_FEATURES
                ).map(({ title }) => (
                  <PlanFeatureItem title={title} key={title} />
                ))}
              </ul>

              <ul className={styles.rightSideFeatures}>
                {(isUltraModal
                  ? EXTERNAL_MODELS_ULTRA_LIST
                  : EXTERNAL_MODELS_LIST
                ).map((item, index) => (
                  <li className={styles.listItem} key={index}>
                    {item.icon}
                    <span className={styles.text}>{item.title}</span>
                  </li>
                ))}
                {isUltraModal && (
                  <li className={styles.moreModelsText}>16+ more models</li>
                )}
              </ul>
            </div>
          </div>
        </div>

        <div className={styles.footerWrapper}>
          <div
            className={classNames(styles.footer, {
              [styles.reverseOrder]: reverseButtonsOrder,
            })}
          >
            {isEnterpriseModal && (
              <a
                href={DEFAULT_NINJATECH_LINK}
                target="_blank"
                rel="noreferrer"
                className={styles.learnMoreLink}
              >
                <>
                  <ArrowSquareOut size={SVG_SIZE_M} />
                  <span>Learn more</span>
                </>
              </a>
            )}

            <button
              type="button"
              className={classNames(styles.button, {
                [styles.active]: isContinueButtonBlue,
              })}
              onClick={handleContinueClick}
            >
              {continueButtonTitle}
            </button>

            {!isEnterpriseModal && !isSubscribedModal && (
              <button
                id={upgradeButtonId}
                type="button"
                className={classNames(styles.button, {
                  [styles.active]: isUpgradeButtonBlue,
                })}
                onClick={onUpgrade ?? handleUpgradeClick}
              >
                Get $5 Off Today
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
