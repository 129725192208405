import './UpgradeForm.scss';
import { GTMEvent, PaymentPlanInterval, PlanSectionType } from 'src/types';
import {
  PRO_FEATURES,
  PRO_MODELS,
  SUBSCRIPTIONS,
  SVG_SIZE_M,
  ULTRA_FEATURES,
  ULTRA_MODELS,
} from 'src/constants';
import { Check, CheckCircle, Image } from '@phosphor-icons/react';
import { EXTERNAL_MODELS_LINK } from 'src/constants/externalLinks';
import { sendGTMEvent } from 'src/utils';

type UpgradeFormProps = {
  onOpenCheckout: (type: PlanSectionType) => void;
  period: PaymentPlanInterval;
  type: PlanSectionType;
  isSelected: boolean;
};

export const UpgradeForm = ({
  onOpenCheckout,
  period,
  type,
  isSelected,
}: UpgradeFormProps) => {
  const isMonthlyPeriod = period === PaymentPlanInterval.MONTHLY;
  const isPro = type === 'Pro';

  const subscriptionInfo = SUBSCRIPTIONS.find((item) => item.plan === type);

  const handleViewAllModelsClick = () => {
    sendGTMEvent(GTMEvent.PAYMENT_VIEW_ALL_MODELS_CLICK);
  };

  return (
    <div className="nj-upgrade-form">
      <div className="nj-plan-section--current-plan">
        <p className="nj-plan-section--current-plan-price">
          {!isMonthlyPeriod && (
            <span className="nj-plan-section--current-plan-original-price">
              <s>${subscriptionInfo?.originalPrice}</s>
              &nbsp;
            </span>
          )}

          <span className="nj-plan-section--current-plan-title">
            $
            {isMonthlyPeriod
              ? subscriptionInfo?.originalPrice
              : subscriptionInfo?.yearlyPrice}
            &nbsp;
          </span>

          <span className="nj-plan-section--current-plan-subtitle">/mo</span>
        </p>
        <p className="nj-plan-section--current-plan-sales-description">
          {isMonthlyPeriod
            ? `$${subscriptionInfo?.firstMonthSale} off first month`
            : 'Billed annually'}
        </p>
        <span className="nj-plan-section--current-plan-description colored">
          Unlimited tasks
        </span>
      </div>
      {isSelected ? (
        <button type="button" className="nj-upgrade-form--selected" disabled>
          <CheckCircle size={SVG_SIZE_M} weight="fill" />
          <span>Selected</span>
        </button>
      ) : (
        <button
          type="button"
          className="nj-button nj-upgrade-form--submit"
          onClick={() => onOpenCheckout(type)}
          id="Plan-card-upgrade"
          // TODO(olha): will be implement in the next PR
          // disabled={isLoading || !data}
        >
          Get {type}
        </button>
      )}
      <div className="nj-plan-section--current-plan-features-list">
        <h5 className="nj-plan-section--current-plan-features-list-title">
          {isPro ? 'Everything in Basic, plus' : 'Everything in Pro, plus'}
        </h5>
        {(isPro ? PRO_FEATURES : ULTRA_FEATURES).map((item) => (
          <div
            className="nj-plan-section--current-plan-feature-row"
            key={item.id}
          >
            <Check size={SVG_SIZE_M} />
            <span>{item.title}</span>
          </div>
        ))}
        <hr className="divider" />
        <div className="nj-plan-section--current-plan-models-list">
          <h5 className="nj-plan-section--current-plan-models-list-title">
            <Check size={SVG_SIZE_M} />
            <span>
              <span className="colored">Unlimited </span>
              <span>
                {isPro
                  ? 'access to 6 AI models *'
                  : 'access to 20+ premium AI models *'}
              </span>
            </span>
          </h5>
          <div className="nj-plan-section--current-plan-models-list-content">
            {(isPro ? PRO_MODELS : ULTRA_MODELS).map((item) => (
              <div
                className="nj-plan-section--current-plan-models-list-item"
                key={item.id}
              >
                {item.icon}
                <div>{item.text}</div>
                {item.isShowImageIcon && (
                  <div className="nj-plan-section--current-plan-models-list-item-image">
                    <Image size={SVG_SIZE_M} weight="fill" />
                  </div>
                )}
              </div>
            ))}
            {!isPro && (
              <a
                href={EXTERNAL_MODELS_LINK}
                target="_blank"
                rel="noreferrer"
                className="nj-plan-section--current-plan-models-list-view-all-link"
                onClick={handleViewAllModelsClick}
              >
                View all models
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
