import { useMemo } from 'react';
import { useSession } from 'src/hooks';
import { LabelPro } from 'src/components/LabelPro';
import { LabelFree } from 'src/components/LabelFree';
import { LabelProTrial } from 'src/components/FlatAppearance/components/LabelProTrial';
import { LabelEnterpriseTrial } from 'src/components/LabelEnterpriseTrial';
import { LabelUltra } from 'src/components/LabelUltra';

export const CurrentPlanLabel = () => {
  const {
    isUltraTier,
    isProTier,
    isFreeTier,
    isEnterpriseTier,
    isProTrialTier,
  } = useSession();

  const currentPlan = useMemo(() => {
    if (isFreeTier) {
      return <LabelFree />;
    }
    if (isProTrialTier) {
      return <LabelProTrial />;
    }
    if (isProTier) {
      return <LabelPro labelType="full" />;
    }
    if (isEnterpriseTier) {
      return <LabelEnterpriseTrial />;
    }
    if (isUltraTier) {
      return <LabelUltra labelType="full" />;
    }
    return null;
  }, [isFreeTier, isProTrialTier, isProTier, isEnterpriseTier, isUltraTier]);

  return currentPlan;
};
