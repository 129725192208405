/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum UserTier {
  OPEN = 'open',
  FREE = 'free',
  PRO_TRIAL = 'pro_trial',
  TEAM = 'team',
  ENTERPRISE_TRIAL = 'enterprise_trial',
  PRO = 'pro',
  ULTRA = 'ultra',
  TESTING = 'testing',
  PAID = 'paid',
}
