import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { batch } from 'react-redux';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import {
  useQueryParams,
  useSubmitUserInput,
  useSession,
  useConversations,
} from 'src/hooks';
import {
  DEFAULT_CHAT_ID,
  REDIRECT_TO_STRIPE_TIMESTAMP,
  TRANSACTION_ID,
} from 'src/constants';
import { sendGTMEvent } from 'src/utils';
import {
  AppRoutes,
  BannerType,
  GTMEvent,
  WelcomeUpsellModalType,
  NinjaSubscriptionPlan,
} from 'src/types';
import { useBanner } from 'src/hooks/useBanner';
import { useLazyGetSessionInfoQuery } from 'src/store/services';
import {
  useAppDispatch,
  toggleWelcomeModal,
  setWelcomeUpsellModalType,
} from 'src/store';

/**
 * useChatPageQueryParams handles all query parameters on the Main chat page.
 */
export const useChatPageQueryParams = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const {
    searchParams: {
      session_id,
      query,
      subscription_succeeded_plan,
      subscription_succeeded_period,
    },
    removeSearchParam,
  } = useQueryParams();
  const { onSubmitUserInput } = useSubmitUserInput();
  const { removeBanners } = useBanner();
  const { appUser } = useSession();

  const [checkSession] = useLazyGetSessionInfoQuery();

  const { isConversationsLoading, isConversationsFetching, conversations } =
    useConversations();

  const { setThreadInputBoxValue } = useContext(ThreadInputBoxContext);

  // TODO(olha): this hook will be deprecated soon (After Payments V2)
  useEffect(() => {
    if (session_id) {
      const handleCheckSession = async () => {
        try {
          await checkSession({
            user_id: appUser.user_id,
            session_id: session_id,
          }).unwrap();

          // !!! (olha): it's an important event we are tracking. We need to make sure it calls properly and only once by the subscription
          sendGTMEvent(GTMEvent.SUCCESSFUL_NEW_PRO_SUBSCRIPTION);
        } finally {
          removeBanners([
            BannerType.INSUFFICIENT_CREDITS,
            BannerType.LOW_CREDITS,
            BannerType.LOW_TASKS,
          ]);

          removeSearchParam('session_id');

          if (!!localStorage.getItem(REDIRECT_TO_STRIPE_TIMESTAMP)) {
            localStorage.removeItem(REDIRECT_TO_STRIPE_TIMESTAMP);
          }
        }
      };

      handleCheckSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session_id]);

  const handleSentQuery = (query: string) => {
    const decodedQuery = decodeURIComponent(query);

    setThreadInputBoxValue(decodedQuery);
    onSubmitUserInput(decodedQuery, {
      conversation_id: DEFAULT_CHAT_ID,
    });
  };

  // Adding the text from the "query" param to the Input box and sending a request.
  useEffect(() => {
    if (
      !appUser.user_id ||
      isConversationsLoading ||
      isConversationsFetching ||
      !conversations
    ) {
      return;
    }

    if (query) {
      removeSearchParam('query');
      handleSentQuery(query);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    query,
    appUser.user_id,
    isConversationsLoading,
    isConversationsFetching,
    conversations,
  ]);

  // handling succeeded payment
  useEffect(() => {
    if (!subscription_succeeded_plan || !subscription_succeeded_period) {
      return;
    }
    const transaction_id = localStorage.getItem(TRANSACTION_ID);

    if (transaction_id) {
      localStorage.removeItem(TRANSACTION_ID);

      // !!! (olha): it's an important event we are tracking. We need to make sure it calls properly and only once by the subscription
      sendGTMEvent(GTMEvent.SUCCESSFUL_NEW_PRO_SUBSCRIPTION, {
        transaction_id: transaction_id,
        subscription_plan: subscription_succeeded_plan,
        subscription_period: subscription_succeeded_period,
      });

      const modalType: WelcomeUpsellModalType =
        subscription_succeeded_plan === NinjaSubscriptionPlan.ULTRA
          ? 'ultra-welcome-modal'
          : 'pro-welcome-modal';

      batch(() => {
        dispatch(toggleWelcomeModal(true));
        dispatch(setWelcomeUpsellModalType(modalType));
      });
    }

    removeSearchParam('subscription_succeeded_plan');
    navigate(AppRoutes.HOME, { replace: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription_succeeded_plan, subscription_succeeded_period]);
};
