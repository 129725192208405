import { useRef } from 'react';
import { Tooltip } from 'react-tooltip';
import { useCollapse } from 'react-collapsed';
import { DotsThreeOutlineVertical } from '@phosphor-icons/react';
import { Message, MessageType } from 'src/types';
import { Feedback } from 'src/types/models/Feedback';
import { SVG_SIZE_M } from 'src/constants';
import { useBreakpoint, useOutsideClick, useVisible } from 'src/hooks';
import { MessageThumbsButtons } from 'src/pages/ManageTasksChatPage/components/ConversationFeedback/components/NonDebugModeThumbsFeedback/components/MessageThumbsButtons';
import styles from './FooterMenu.module.scss';
import classNames from 'classnames';
import { RedoSkillActions } from '../../../RedoSkillActions';
import { RedoWithPersona } from '../../../RedoWithPersona';
import { BottomDrawer } from 'src/components/BottomDrawer';

type GetToggleProps = ReturnType<typeof useCollapse>['getToggleProps'];

interface FooterMenuProps {
  message: Message;
  taskId?: string;
  messageId?: string;
  conversationId?: string;
  feedback?: Feedback;
  disabled?: boolean;
  messageType?: MessageType;
  onToggleNegativeFeedbackForm: GetToggleProps;
  onCloseRedoSkillActions: () => void;
  onCloseRedoWithPersona: () => void;
  onCloseNegativeFeedbackForm: () => void;
  isShowNegativeFeedbackForm?: boolean;
  isShowRedoWithPersona?: boolean;
  onToggleRedoWithPersona: GetToggleProps;
  isShowRedoSkillActions?: boolean;
  onToggleRedoSkillActions: GetToggleProps;
}

// TODO(olha): component needs refactoring
export const FooterMenu = ({
  message,
  taskId,
  messageId,
  conversationId,
  feedback,
  disabled,
  messageType,
  onToggleNegativeFeedbackForm,
  onCloseRedoSkillActions,
  onCloseRedoWithPersona,
  onCloseNegativeFeedbackForm,
  isShowNegativeFeedbackForm = false,
  isShowRedoWithPersona,
  isShowRedoSkillActions,
  onToggleRedoWithPersona,
  onToggleRedoSkillActions,
}: FooterMenuProps) => {
  const buttonRef = useRef(null);

  const { isMobileOrTablet } = useBreakpoint();

  const { isVisible, handleVisibilityRemove, handleVisibilityToggle } =
    useVisible();

  const handleCloseWithDelay = () => {
    setTimeout(() => {
      handleVisibilityRemove();
    }, 100);
  };

  useOutsideClick(buttonRef, handleCloseWithDelay);

  const menu = (
    <>
      {message.message_type !== MessageType.IMAGE_CARD && (
        <>
          <RedoSkillActions
            message={message}
            isShowRedoSkillActions={isShowRedoSkillActions}
            onToggleRedoSkillActions={onToggleRedoSkillActions}
            onCloseRedoWithPersona={onCloseRedoWithPersona}
            onCloseNegativeFeedbackForm={onCloseNegativeFeedbackForm}
          />
          <RedoWithPersona
            message={message}
            isShowRedoWithPersona={isShowRedoWithPersona}
            onToggleRedoWithPersona={onToggleRedoWithPersona}
            onCloseRedoSkillActions={onCloseRedoSkillActions}
            onCloseNegativeFeedbackForm={onCloseNegativeFeedbackForm}
          />

          <hr className="divider" />
        </>
      )}

      <MessageThumbsButtons
        taskId={taskId}
        messageId={messageId}
        conversationId={conversationId}
        messageType={messageType}
        feedback={feedback}
        onToggleNegativeFeedbackForm={onToggleNegativeFeedbackForm}
        isShowNegativeFeedbackForm={isShowNegativeFeedbackForm}
        onCloseRedoSkillActions={onCloseRedoSkillActions}
        onCloseRedoWithPersona={onCloseRedoWithPersona}
        onCloseNegativeFeedbackForm={onCloseNegativeFeedbackForm}
        disabled={disabled}
        withTitle
      />
    </>
  );

  return (
    <>
      <button
        className={classNames(styles.trigger, {
          [styles.active]: isVisible,
        })}
        ref={buttonRef}
        onClick={handleVisibilityToggle}
        id="footer-tooltip"
      >
        <DotsThreeOutlineVertical
          weight={isVisible ? 'fill' : 'regular'}
          size={SVG_SIZE_M}
        />
      </button>

      {isMobileOrTablet ? (
        <BottomDrawer isOpen={isVisible} onClose={handleVisibilityRemove}>
          {menu}
        </BottomDrawer>
      ) : (
        <Tooltip
          isOpen={isVisible}
          place="top-start"
          clickable
          className="nj-message-footer-tooltip"
          noArrow
          anchorSelect="#footer-tooltip"
          openOnClick
        >
          {menu}
        </Tooltip>
      )}
    </>
  );
};
