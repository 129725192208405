import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { FormProvider } from 'src/components/FormProvider';
import { Modal } from 'react-responsive-modal';
import { X } from '@phosphor-icons/react';
import { Checkbox } from 'src/components/Checkbox';
import styles from 'src/pages/ManageAccountPage/components/General/General.module.scss';

interface DeleteAccountFormData {
  'delete-account-checkbox': boolean;
}

interface DeleteAccountModalProps {
  open: boolean;
  title: string;
  submitTitle: string;
  onClose: () => void;
  onSubmit: (value: DeleteAccountFormData) => void;
  children: React.ReactNode;
}

const SVG_SIZE = 24;

export const DeleteAccountModal = ({
  open,
  title,
  submitTitle,
  onClose,
  onSubmit,
  children,
}: DeleteAccountModalProps) => {
  const { rootContainerRef } = useContext(ForwardRefContext);
  const methods = useForm<DeleteAccountFormData>();
  const { handleSubmit, watch } = methods;

  // Watch the checkbox value
  const checkboxChecked = watch('delete-account-checkbox', false);

  const handleFormSubmit = (value: DeleteAccountFormData) => {
    onSubmit(value);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      container={rootContainerRef?.current || document.body}
      classNames={{
        root: 'nj-modal--root',
        overlay: 'nj-modal--overlay',
        modal: 'nj-modal--access',
      }}
      closeIcon={<X size={SVG_SIZE} />}
      center
    >
      <FormProvider<DeleteAccountFormData> methods={methods}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="nj-modal--access--wrapper">
            <p className="nj-modal--access--title">{title}</p>

            {children}
            <table>
              <tbody>
                <tr>
                  <td>
                    <Checkbox
                      label=""
                      name="delete-account-checkbox"
                    />
                  </td>
                  <td>
                    I confirm that I understand my account will be permanently deleted and cannot be recovered.
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="nj-modal--access--action-wrapper">
              <button
                type="button"
                className={styles.deleteAccountCancelButton}
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={
                  checkboxChecked
                    ? `${styles.deleteAccountAcceptButton} ${styles.enabledButton}`
                    : `${styles.deleteAccountAcceptButton} ${styles.disabledButton}`
                }
                disabled={!checkboxChecked}
              >
                {submitTitle}
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};
