import { useContext, useState, useEffect, useMemo } from 'react';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import AudioContext from 'src/contexts/AudioContext';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import {
  useVisible,
  useSession,
  useBreakpoint,
  useConversationParams,
} from 'src/hooks';
import { ChatMode } from 'src/types';
import { useDeleteAttachment } from './useDeleteAttachment';

export const useInputBoxState = () => {
  const { threadInputBoxRef } = useContext(ForwardRefContext);
  const { recordInProgress } = useContext(AudioContext);
  const { setThreadInputBoxValue, setTemporaryInputValue } = useContext(
    ThreadInputBoxContext,
  );
  const { isDesktopAndUp } = useBreakpoint();
  const { chatMode } = useSession();
  const { currentConversationId } = useConversationParams();
  const {
    isVisible: isVisibleContactPicker,
    handleVisibilitySet: onOpenContactPicker,
    handleVisibilityRemove: onCloseContactPicker,
  } = useVisible();

  const {
    isVisible: isVisibleCommandPicker,
    handleVisibilitySet: onOpenCommandPicker,
    handleVisibilityRemove: onCloseCommandPicker,
  } = useVisible();

  const { onDeleteAttachment } = useDeleteAttachment();

  const [shouldNotSubmitOnEnter, setNotSubmitOnEnter] =
    useState<boolean>(false);

  // disable submit while QuickPicker is opened
  useEffect(() => {
    setNotSubmitOnEnter(isVisibleContactPicker || isVisibleCommandPicker);
  }, [isVisibleContactPicker, isVisibleCommandPicker]);

  // auto-scrolling content to the bottom within the input box while text-to-speech
  useEffect(() => {
    if (threadInputBoxRef?.current && recordInProgress) {
      threadInputBoxRef.current.scrollTop =
        threadInputBoxRef?.current.scrollHeight;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threadInputBoxRef?.current?.scrollHeight, recordInProgress]);

  const isAvatarMode = useMemo(() => chatMode === ChatMode.AVATAR, [chatMode]);

  // clear input by change conversation or mode
  useEffect(() => {
    setTemporaryInputValue('');
    setThreadInputBoxValue('');
    onDeleteAttachment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentConversationId, isAvatarMode]);

  useEffect(() => {
    if (isDesktopAndUp) {
      threadInputBoxRef?.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threadInputBoxRef, isDesktopAndUp]);

  return {
    shouldNotSubmitOnEnter,
    isVisibleContactPicker,
    isVisibleCommandPicker,
    onOpenContactPicker,
    onCloseContactPicker,
    onOpenCommandPicker,
    onCloseCommandPicker,
  };
};
