import { ImageGenerationModelOptions, imageModelOptions } from 'src/constants';
import { useSession } from 'src/hooks';
import styles from './ImagesModelSelector.module.scss';
import { SelectSimple } from 'src/components/SelectSimple';
import { LabelPro } from 'src/components/LabelPro';

interface ImagesPerModelToggleProps {
  selectedValue?: ImageGenerationModelOptions;
  onChange: (value: ImageGenerationModelOptions) => Promise<void>;
}

const IMAGE_MODEL = 'IMAGE_MODEL';

export const ImagesModelSelector = ({
  selectedValue = ImageGenerationModelOptions.BOTH,
  onChange,
}: ImagesPerModelToggleProps) => {
  const { isOpenTier, isFreeTier } = useSession();

  const isDisabled = isOpenTier || isFreeTier;

  return (
    <div className={styles.root}>
      <label className={styles.label}>
        <span>Model</span>
        {isDisabled && <LabelPro />}
      </label>
      <SelectSimple
        aria-label="image-model"
        name={IMAGE_MODEL}
        options={imageModelOptions}
        className={styles.select}
        onChange={onChange}
        isDisabled={isDisabled}
        value={selectedValue}
      />
    </div>
  );
};
